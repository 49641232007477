import Vue from "vue";
//import $ from "jquery";
import NProgress from "nprogress";

//import services
import Util from "./services/util";
import Store from "./services/store";
import Wishlist from "./services/wishlist";

//global components
Vue.component("Loader", require("./components/loader/loader.vue").default);
Vue.component("Signup", require("./components/signup/signup.vue").default);
Vue.component("Header", require("./components/header/header.vue").default);
Vue.component("Footer", require("./components/footer/footer.vue").default);
Vue.component("Divider", require("./components/divider/divider.vue").default);
Vue.component("Confirm", require("./components/confirm/confirm.vue").default);
Vue.component(
  "HelpText",
  require("./components/help-text/help-text.vue").default
);
Vue.component(
  "FooterBottom",
  require("./components/footer-bottom/footer-bottom.vue").default
);
Vue.component(
  "ImageLoader",
  require("./components/image-loader/image-loader.vue").default
);
Vue.component(
  "TextWrapper",
  require("./components/text-wrapper/text-wrapper.vue").default
);
Vue.component(
  "SkeletonLoader",
  require("./components/skeleton-loader/skeleton-loader.vue").default
);
Vue.component(
  "ClearCartConfirm",
  require("./components/clear-cart-confirm/clear-cart-confirm.vue").default
);
Vue.component(
  "ProductItemQtyHandler",
  require("./components/product-item-qty-handler/product-item-qty-handler.vue")
    .default
);

//import components
import StoreInactive from "./components/store-inactive/store-inactive.vue";
import TopBanner from "./components/top-banner/top-banner.vue";
import CartFooter from "./components/cart-footer/cart-footer.vue";

//views - for dynamic routing
import SingleVendorHome from "./views/single-vendor-home/single-vendor-home.vue";
import MultiVendorHome from "./views/multi-vendor-home/multi-vendor-home.vue";

export default {
  name: "App",

  mounted() {
    this.setFavicon();

    const pathname = window.location.pathname;
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    if (pathname && hostname == this.ghostname) {
      const storeId = pathname.replace("/", "");
      if (this.isID(storeId)) {
        this.verifyStore(storeId);
        return;
      }
    }

    if (protocol == "http:" && hostname != "localhost") {
      this.isStoreDown = true;
    } else {
      this.verifyStore();
    }
  },

  components: {
    StoreInactive,
    TopBanner,
    CartFooter,
  },

  methods: {
    verifyStore(storeId = false) {
      this.loading = true;
      return Store.verifyStore(this.hostname, storeId)
        .then((resp) => {
          this.store = resp.data;
          this.$store.state.storeInfo = resp.data;

          if (!this.$store.state.authenticated) this.$store.dispatch("verify");
          else this.processWishlist();

          this.setCountry();
          this.fetchWbstore();
          this.processRouting();
          this.processDeeplink();
        })
        .catch(() => {
          //console.log(err);
          NProgress.done();
          this.loading = false;
          this.isStoreDown = true;
        });
    },

    isID(keyword) {
      if (!keyword) return false;
      const regexp = new RegExp("^([a-f\\d]{24})$");
      const lcaseKeyword = keyword.toLowerCase();
      if (
        lcaseKeyword &&
        lcaseKeyword.length == 24 &&
        regexp.test(lcaseKeyword)
      )
        return true;
      return false;
    },

    setCountry() {
      const country = this.store.country;
      if (country && country._id) this.$store.state.country = country;
    },

    fetchWbstore() {
      return Store.getWebstore(this.store._id)
        .then((resp) => {
          this.$store.state.storeInfo.webstore = resp.data;
          this.store = this.$store.state.storeInfo;
          this.processStoreSetup();
          this.loading = false;
        })
        .catch((e) => console.log(e));
    },

    fetchWishlist() {
      return Wishlist.getWishlist(this.store._id)
        .then((x) => {
          if (x.data) this.$store.state.wishlist = x.data;
        })
        .catch((e) => console.log(e));
    },

    processStoreSetup() {
      this.setTitle();
      this.setDescription();
      this.setFavicon();
      this.processMetaScripts();

      const primaryColor = (
        this.store.webstore?.color || this.store.appColor
      ).substring(0, 7);
      this.$vuetify.theme.themes.light.primary = primaryColor;
      document.documentElement.style.setProperty("--primary", primaryColor);
    },

    processMetaScripts() {
      // if (this.store.webstore?.meta) {
      //   $("head").append(this.store.webstore.meta);
      // }
      // if (this.store.webstore?.scripts) {
      //   $("head").append(this.store.webstore.scripts);
      // }

      // if (this.store.webstore?.noscripts) {
      //   $(this.store.webstore.noscripts).prependTo("body");
      // }

      const mapKey =
        this.store.googleAPIKeyWeb ||
        this.store.googleAPIKey ||
        this.$store.state.meta.googleAPIKey;
      if (mapKey) {
        let url =
          "https://maps.googleapis.com/maps/api/js?key=" +
          mapKey +
          "&libraries=geometry,places";

        if (mapKey != this.$store.state.meta.googleAPIKey) {
          url += ",distancematrix";
        }
        const scripts = document.getElementsByTagName("script");

        let isAdded = false;
        for (let i = scripts.length; i--; ) {
          if (scripts[i].src == url) return (isAdded = true);
        }

        if (!isAdded) {
          const script = document.createElement("script");
          script.src = url;
          script.type = "text/javascript";
          document.head.appendChild(script);
        }
      }
    },

    setFavicon() {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = this.store.webstore?.favicon
        ? Store.getAssetURL(this.store.webstore.favicon)
        : Store.logoURL();
    },

    setTitle() {
      document.title = this.store.webstore?.title || this.store.appName;
    },

    setDescription() {
      document.description =
        this.store.webstore?.description || this.store.appName;
    },

    processRouting() {
      const si = this.$store.state.storeInfo;
      this.$store.state.storeInfo.isCMV =
        si.isMultivendor && si.multivendorUIType === "categories";

      this.isMV =
        si.isMultivendor &&
        si.hasVendors &&
        (!si.multivendorUIType || si.multivendorUIType === "vendors");
      this.$store.state.storeInfo.isMV = this.isMV;
      if (this.isMV) this.$router.addRoute(this.getRoute("mv"));
      else this.$router.addRoute(this.getRoute("sv"));
    },

    getRoute(mode) {
      const svRoute = {
        path: "/",
        alias: "/:_id([a-f\\d]{24})",
        name: "single-vendor-home",
        component: SingleVendorHome,
        meta: { requiresAuth: false },
      };
      const mvRoute = {
        path: "/",
        alias: "/:_id([a-f\\d]{24})",
        name: "multi-vendor-home",
        component: MultiVendorHome,
        meta: { requiresAuth: false },
      };
      return mode == "sv" ? svRoute : mvRoute;
    },

    processStoreMisc() {
      const si = this.$store.state.storeInfo;
      const displaySignup = () => {
        if (!this.$store.getters.authStatus)
          this.$store.state.signupModal = true;
      };

      if (si.forceSignupEnabled) displaySignup();
      //else setTimeout(() => displaySignup(), 12500);

      this.initializeCart();
    },

    initializeCart() {
      const cart = this.$store.state.cart;
      const cartString = localStorage[cart.cartKey];
      if (cartString) {
        const jsonString = Util.decrypt(cartString, cart.secretKey);
        this.$store.state.cart.items = JSON.parse(jsonString);
      }

      //update the cart from server
      this.$store.commit("updateCart");
    },

    processWishlist() {
      if (!this.store.isMultivendor) this.fetchWishlist();
    },

    processDeeplink() {
      const incomingSlug = this.$route.query.incoming_slug;
      const urlSource = this.$route.query.url_source;
      console.log("incomingSlug", incomingSlug);
      console.log("urlSource", urlSource);
    },
  },

  watch: {
    "$store.state.authenticated"(n) {
      if (n) this.processWishlist();
    },

    "$store.state.loadingCompleted"(n) {
      if (n) this.processStoreMisc();
    },
  },

  computed: {
    routeName() {
      return this.$route.name;
    },

    showHeader() {
      return ![
        "checkout",
        "checkout-subscription",
        "checkout-success",
        "checkout-subscription-success",
      ].includes(this.routeName);
    },

    showFooter() {
      return ![
        "checkout",
        "checkout-subscription",
        "checkout-success",
        "checkout-subscription-success",
      ].includes(this.routeName);
    },

    showCartFooter() {
      return ![
        "checkout",
        "checkout-subscription",
        "account",
        "product",
        "account-slug",
        "checkout-success",
        "checkout-subscription-success",
      ].includes(this.routeName);
    },
  },

  data() {
    return {
      hostname: window.location.hostname,
      ghostname: "web.growcify.com",
      isStoreDown: false,
      isMV: false,

      loading: false,
      store: {},

      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
};
