export default {
  name: "MultiVendorHome",

  created() {
    document.title = "Something went wrong";
  },

  data() {
    return {
      href: window.location.href,
      hostname: window.location.hostname,
      img: require("@/assets/imgs/empty-cart.gif"),
    };
  },
};
