import Cart from '../../services/cart';
import Util from '../../services/util';
import Wishlist from '../../services/wishlist';

export default {
  props: [
    'type',
    'large',
    'small',
    'isCart',
    'product',
    'wishlist',
    'noElevation'
  ],

  methods: {
    remove() {
      return Cart.remove(this.product);
    },

    processWishlist() {
      Wishlist.processWishlist(this.store._id, this.product);
    },

    subscribe() {
      const sitem = this.product;
      sitem.qty = 1;
      this.$store.state.subscriptionCart.item = sitem;
      this.$router.push({ name: 'checkout-subscription' }).catch(() => {});
    },

    add() {
      if (!this.product.orderCap || (Cart.getCount(this.product) < this.product.orderCap)) {
        if (Cart.getCount(this.product) < this.product.quantity) { //do not allow more than available quantities
          Cart.add(this.product); //check for add-ons; if there is, show them
        } else {
          this.showQtyAlert(this.product.quantity)
        }
      } else {
        this.showQtyAlert(this.product.orderCap);
      }
    },

    showQtyAlert(cap) {
      const text = `You can add ${cap} ${cap === 1 ? `quantity` : `quantities`} only!`;
      this.$store.state.snackbar.text = text;
      this.$store.state.snackbar.timeout = 2500;
      this.$store.state.snackbar.color = 'error';
      this.$store.state.snackbar.show = true;
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    getPrice() {
      return Cart.getPrice(this.product);
    },

    getCount() {
      return Cart.getCount(this.product);
    },

    isAdded() {
      return Cart.isAdded(this.product);
    },

    isInWishlist() {
      return Wishlist.isInWishlist(this.product._id);
    },

    processingWishlist() {
      return Wishlist.isProcessing();
    },

    canSubscribe() {
      const plan = this.store.plan;
      return (Util.isPremiumPlan(plan) || Util.isSinglevendorPremiumPlan(plan)) && this.store.subscriptionEnabled && this.product.isSubscribable;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  }
}