import Store from '../../../services/store';

export default {
  name: 'Coupons',

  methods: {
    fetchCoupons() {
      this.loading = true;
      return Store  
        .getCoupons(this.store._id, this.isSubscription ? 'subscriptions' : 'orders')
        .then(res => {
          this.coupons = res.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },

    close() {
      this.$store.state.couponsModal = false;
    },

    copy(code) {
      navigator.clipboard.writeText(code);

      this.copied = true;
      setTimeout(() => this.copied = false, 2500);
    }
  },

  watch: {
    '$store.state.couponsModal'(n) {
      if (n && this.coupons && !this.coupons.length) this.fetchCoupons();
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    isSubscription() {
      return this.$parent.isSubscription;
    }
  },

  data() {
    return {
      loading: false,
      copied: false,
      coupons: []
    }
  }
}