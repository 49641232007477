import Wishlist from '../../../services/wishlist';

import ProductItem from '../../../components/product-item/product-item.vue';
import NotFound from '../../../components/not-found/not-found.vue';

export default {
  name: 'Wishlist',

  created() {
    if (!this.user._id) return this.$router.push({ name: 'account' }).catch(() => {});
    this.fetchWishlist();
  },

  components: {
    ProductItem,
    NotFound
  },

  methods: {
    fetchWishlist() {
      this.loading = true;
      return Wishlist
        .getDetailedWishlist(this.store._id, this.user._id)
        .then(x => {
          this.wishlist = x.data;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  watch: {
    wishlistModified(n) {
      if (n) this.fetchWishlist();
      this.wishlistModified = false;
    }
  },

  data() {
    return {
      loading: false,
      processing: false,
      wishlistModified: false,

      wishlist: []
    }
  }
}