import Vue from 'vue';

export default {
  createSubscription(_id, subscription) {
    return Vue.axios.post(`subscription/${_id}`, subscription, {ngProgress: false});
  },

  getSubscriptions(_id, userId, status) {
    return Vue.axios.get(`subscription/list/${status}/${_id}/${userId}`, {ngProgress: false});
  },

  getSubscription(subscriptionId) {
    return Vue.axios.get(`subscription/${subscriptionId}`, {ngProgress: false});
  },

  cancelSubscription(subscriptionId) {
    return Vue.axios.put(`subscription/cancelled/${subscriptionId}`, {}, {ngProgress: false});
  },

  editSubscription(subscriptionId, subscription) {
    return Vue.axios.put(`subscription/${subscriptionId}`, subscription, {ngProgress: false});
  },

  skipSchedule(subscriptionId, subscription) {
    return Vue.axios.post(`subscription/schedule/skip/${subscriptionId}`, subscription, {ngProgress: false});
  },

  removeSchedule(subscriptionId, subscription) {
    return Vue.axios.post(`subscription/schedule/remove/${subscriptionId}`, subscription, {ngProgress: false});
  },

  undoSchedule(subscriptionId, subscription) {
    return Vue.axios.post(`subscription/schedule/undo/${subscriptionId}`, subscription, {ngProgress: false});
  },

  addSchedule(subscriptionId, subscription) {
    return Vue.axios.post(`subscription/schedule/add/${subscriptionId}`, subscription, {ngProgress: false});
  }
}