<template>
  <v-container>
    <div class="mt-10"></div>
    <v-row>
      <v-col>
        <h2 class="mt-10">About Us</h2>
        <div class="mb-10"></div>

        <div v-if="loading">
          <v-progress-linear indeterminate height="3px"></v-progress-linear>
        </div>

        <div
          v-else-if="about"
          class="ql-editor"
          v-html="about.description"
          style="white-space: pre-wrap"
        ></div>

        <div v-else>-</div>

        <div class="mb-15"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import $ from "jquery";
import Util from "../../services/util";
import Store from "../../services/store";

export default {
  created() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    document.title = "About Us";

    this.fetchAbout();
  },

  destroyed() {
    Util.setTitle();
  },

  methods: {
    fetchAbout() {
      this.loading = true;
      return Store.getAbout(this.store._id)
        .then((x) => {
          this.about = x.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },
  },

  data() {
    return {
      about: "",
      loading: false,
    };
  },
};
</script>
