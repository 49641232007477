<template>
  <v-row>
    <v-col class="text-center">
      <div style="height: 75px"></div>

      <img :src="emptyCartGIF" height="200">
      <h3 class="mt-8 mb-3">{{ title || "Oops! We couldn't find any matches!" }}</h3>
      <span>{{ subtitle || "Please try searching for something else" }}</span>

      <div class="mt-8">
        <v-btn to="/" text rounded color="primary" class="nott">Go to Home</v-btn>
      </div>
      <div style="height: 75px"></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'NotFound',

  props: [
    'title',
    'subtitle'
  ],

  data() {
    return {
      emptyCartGIF: require("../../assets/imgs/empty-cart.gif")
    }
  }
}
</script>