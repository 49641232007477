import Vue from "vue";
import VueAxios from "vue-axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import AOS from "aos";
import Vue2Editor from "vue2-editor";

//import custom - js
import http from "./http";
import store from "./store";
import router from "./router";

//import custom - vue
import app from "./app.vue";
import vuetify from "./plugins/vuetify";

import "./assets/css/custom.scss";
import "aos/dist/aos.css";

Vue.filter("fc", function (value) {
  const currency = store.state.storeInfo.country.currencyCode || "INR";
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currency,
  }).format(value);
});

Vue.filter("firstLetterUppercase", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.directive("click-outside-cstm", {
  bind() {
    if (!this) return;
    this.event = (event) => this.vm.$emit(this.expression, event);
    this.el.addEventListener("click", this.stopProp);
    document.body.addEventListener("click", this.event);
  },
  unbind() {
    if (!this) return;
    this.el.removeEventListener("click", this.stopProp);
    document.body.removeEventListener("click", this.event);
  },

  stopProp(event) {
    event.stopPropagation();
  },
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vue2Editor);

//use default
Vue.use(VueAxios, http);
Vue.config.productionTip = false;

new Vue({
  created() {
    AOS.init({
      delay: 100,
      duration: 500,
      once: true,
    });
  },
  vuetify,
  router,
  store,
  render: (h) => h(app),
}).$mount("#app");
