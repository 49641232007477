<template>
  <v-footer :color="expanded || $store.state.cart.expanded ? '' : 'white'" v-if="$store.state.cart.items.length" fixed class="elevation-2 p-0">
    <v-progress-linear v-if="$store.state.cart.updating" indeterminate height="3px"></v-progress-linear>
    <v-container class="pt-5 pb-4">
      <section v-if="expanded || $store.state.cart.expanded" class="animate__animated animate__fadeIn">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col class="vc">
                <h3 class="mt-3 mb-0">Your Cart ({{ $store.state.cart.items.length }})</h3>
                  <small>Modify your current cart</small>
              </v-col>

              <v-col class="text-right vc">
                <v-btn fab icon outlined small @click="expanded = false; $store.state.cart.expanded = false;" color="black">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <div class="footer-cart-items">
          <div style="height: 100%; overflow-y:scroll;" class="footer-cart-items-div">
            <v-row v-for="(item, index) in $store.state.cart.items" :key="item._id" class="mt-2 mb-2">
              <v-col cols="12">
                <v-row>
                  <v-col cols="9">
                    <b>{{ item.name }}</b>
                    <h5><b class="text-primary">{{ getPrice(item) | fc }}</b> / {{ item.size }}{{ item.unit }}</h5>
                    <div v-if="store.applyTax && store.taxType === 'product' && item.gst"> 
                      <small>Price Breakup: <b>{{ beforeTaxTotal(item) | fc }}</b> + Tax ({{ item.gst }}%) <b>{{ totalItemTax(item) | fc }}</b></small>
                    </div>
                  </v-col>

                  <v-col class="vc text-right">
                    <ProductItemQtyHandler :small="true" :product="item" :no-elevation="true"></ProductItemQtyHandler>
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="index < $store.state.cart.items.length - 1" cols="12" class="pb-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-row>
          <v-col cols="12" class="mb-2">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </section>

      <v-row>
        <v-col class="vc" cols="6">
          <b>{{ cartTotal | fc }}</b>
          <h5>{{ $store.state.cart.items.length }} item{{ $store.state.cart.items.length == 1 ? '' : 's' }}</h5>
        </v-col>
        <v-col class="text-right vc">
          <v-btn text class="nott elevation-0 rounded-lg hidden-xs-only" large @click="expanded = !expanded" color="primary">
            <b><v-icon large>mdi-chevron-{{expanded ? 'down' : 'up'}}</v-icon></b>
          </v-btn>

          <v-btn class="nott elevation-0 rounded-lg ml-2" large color="primary" to="/checkout/cart">
            <b>Checkout</b>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import Cart from '../../services/cart'
export default {
  name: 'CartFooter',

  methods: {
    getPrice(item) {
      return Cart.getPrice(item);
    },

    getCount(item) {
      return Cart.getCount(item);
    },

    beforeTaxTotal(item) {
      const cartItem = Cart.findProduct(item);
      return cartItem.beforeTaxPrice * cartItem.qty;
    },

    totalItemTax(item) {
      const cartItem = Cart.findProduct(item);
      const amount = cartItem.beforeTaxPrice * cartItem.qty;
      return this.calculateTax(amount, cartItem.gst);
    },

    calculateTax(amount, tax) {
      let taxAmount = 0;
      if (amount && tax) taxAmount = parseFloat(((amount * tax) / 100).toFixed(2));
      return taxAmount;
    },
  },

  computed: {
    cartTotal() {
      return Cart.getTotal();
    },

    store() {
      return this.$store.state.storeInfo;
    }
  },

  data() {
    return {
      expanded: false
    }
  }
}
</script>

<style>
.footer-cart-items {
  overflow: auto;
  max-height: 250px;
}

.footer-cart-items-div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.footer-cart-items-div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>