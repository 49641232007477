<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <img :src="img" height="200px">

        <div class="mt-8"></div>

        <p class="m0 text-18em"><b class="text-primary">{{ hostname }}</b> is currently unavailable.</p>

        <div class="mt-5">
          <div class="mt-8 mb-8">
            <span><b>If you're a visitor to this website</b>, please try again later.</span>
          </div>

          <span class="mb-1"><b>If you're the owner of this website,</b> please <a href="https://dashboard.growcify.com/signin" target="_blank">signin</a> to <br>resolve the issue, or <a href="https://growcify.com" target="_blank">contact support</a>.</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'StoreInactive',

  created() {
    document.title = 'Something went wrong';
  },
  
  data() {
    return {
      href: window.location.href,
      hostname: window.location.hostname,
      img: require("@/assets/imgs/empty-cart.gif")
    }
  }
}
</script>