import Vue from 'vue';
import store from '../store';

export default {
  addWishlist(_id = store.state.storeInfo._id, product, ngProgress = true) {
    return Vue.axios.post(`wishlist/${_id}/add`, { product: product }, {ngProgress: ngProgress});
  },

  removeWishlist(_id = store.state.storeInfo._id, product, ngProgress = true) {
    return Vue.axios.post(`wishlist/${_id}/remove`, { product: product }, {ngProgress: ngProgress});
  },

  isInWishlist(item) {
    return store.state.wishlist.items.includes(item) || !!store.state.wishlist.items.find(x => x && ((x === item) || (x._id == item)));  
  },

  getDetailedWishlist(_id, userId) {
    return Vue.axios.get(`wishlist/list/detailed/${_id}/${userId}`, {ngProgress: false});
  },

  getWishlist(_id = store.state.storeInfo._id, ngProgress = true) {
    return Vue.axios.get(`wishlist/list/${_id}`, {ngProgress: ngProgress});
  },

  isProcessing() {
    return store.state.wishlistProcessing;
  },

  processWishlist(_id, product) {
    if (!store.getters.isAuthenticated) return store.state.isSignin = store.state.signupModal = true;
    const isInWishlist = this.isInWishlist(product._id);
    
    let request;
    if (!isInWishlist) request = this.addWishlist(_id, product._id);
    else request = this.removeWishlist(_id, product._id);
    store.state.wishlistProcessing = true;

    return request
      .then(() => {
        if (!isInWishlist) {
          store.state.wishlist.items.push(product._id);
          this.addedToWishlist();
        } else {
          store.state.wishlist.items = store.state.wishlist.items.filter(x => (x._id ? (x._id != product._id) : (x != product._id)));
          this.removedToWishlist();
        }
        store.state.wishlistProcessing = false;
      })
      .catch(err => {
        console.log(err);
        store.state.wishlistProcessing = false;
      })
  },

  addedToWishlist() {
    store.state.snackbar.text = 'Added to Wishlist';
    store.state.snackbar.color = 'success';
    store.state.snackbar.show = true;
  },

  removedToWishlist() {
    store.state.snackbar.text = 'Removed from Wishlist';
    store.state.snackbar.color = 'error';
    store.state.snackbar.icon = 'mdi-check-all';
    store.state.snackbar.show = true;
  },
}