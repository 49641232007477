import $ from "jquery";
import Store from "../../services/store";

import SectionHeader from "../../components/section-header/section-header.vue";
import CategoryItem from "../../components/category-item/category-item.vue";
import ProductItem from "../../components/product-item/product-item.vue";
import NotFound from "../../components/not-found/not-found.vue";
import Banners from "../../components/banners/banners.vue";
import Deals from "../../components/deals/deals.vue";

export default {
  name: "SingleVendorHome",

  created() {
    $("html, body").animate({ scrollTop: 0 }, 0);
    this.fetchBanners();
    this.fetchDeals();
    this.fetchCategories();
    this.fetchRecentProducts();
    this.fetchTrendingProducts();
    this.fetchRecommendedProducts();
    this.fetchFeaturedProducts();
    this.fetchGroups();
    this.fetchComingSoon();

    this.$store.state.loadingCompleted = true;
  },

  mounted() {
    this.setTitle();
  },

  components: {
    SectionHeader,
    CategoryItem,
    ProductItem,
    NotFound,
    Banners,
    Deals,
  },

  methods: {
    fetchBanners() {
      this.fetchMainBanners();
      this.fetchMiniBanners();
    },

    fetchMainBanners() {
      this.miloading = true;
      return Store.getMainBanners(this.store._id, false)
        .then((x) => {
          this.mainBanners = x.data;
          this.miloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchMiniBanners() {
      this.mnloading = true;
      return Store.getMiniBanners(this.store._id, false)
        .then((x) => {
          this.miniBanners = x.data;
          this.mnloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.mnloading = false;
        });
    },

    fetchDeals() {
      this.dloading = true;
      return Store.getDeals(this.store._id, false)
        .then((x) => {
          this.deals = x.data;
          this.dloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.mnloading = false;
        });
    },

    fetchCategories() {
      this.cloading = true;
      return Store.getCategories(
        this.store._id,
        this.store.homePageCategories || 8,
        false
      )
        .then((x) => {
          this.categories = x.data;
          this.cloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchRecentProducts() {
      this.rploading = true;
      return Store.getRecentProducts(this.store._id, false, 8)
        .then((x) => {
          this.recentProducts = x.data;
          this.rploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.rploading = false;
        });
    },

    fetchTrendingProducts() {
      this.tploading = true;
      return Store.getTrendingProducts(this.store._id, false, 8)
        .then((x) => {
          this.trendingProducts = x.data;
          this.tploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.tploading = false;
        });
    },

    fetchRecommendedProducts() {
      this.rploading = true;
      return Store.getRecommendedProducts(this.store._id, false, 8)
        .then((x) => {
          this.recommendedProducts = x.data;
          this.rploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.rploading = false;
        });
    },

    fetchFeaturedProducts() {
      this.fploading = true;
      return Store.getFeaturedProducts(this.store._id, false, 8)
        .then((x) => {
          this.featuredProducts = x.data;
          this.fploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.fploading = false;
        });
    },

    fetchComingSoon() {
      this.csploading = true;
      return Store.getComingSoonProducts(this.store._id, false, 12)
        .then((x) => {
          this.comingSoonProducts = x.data;
          this.csploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.csploading = false;
        });
    },

    fetchGroups(
      pageNumber = this.groupsObject.pageNumber,
      itemsPerPage = this.groupsObject.itemsPerPage
    ) {
      this.gloading = true;
      return Store.getGroups(this.store._id, pageNumber, itemsPerPage)
        .then((res) => {
          this.groupsObject = res.data;
          this.groups = this.groupsObject.items;
          this.groups = this.groups.filter((x) =>
            ["products", "categories"].includes(x.of)
          );
          this.gloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.gloading = false;
        });
    },

    getOriginalAssetURL(data, sid) {
      return Store.getOriginalAssetURL(data, sid);
    },

    getAssetURL(data) {
      return Store.getAssetURL(data);
    },

    getMainBanners(position, placement = "after") {
      return this.mainBanners.filter(
        (x) => x.position == position && x.placement == placement
      );
    },

    getMiniBanners(position, placement = "after") {
      return this.miniBanners.filter(
        (x) => x.position == position && x.placement == placement
      );
    },

    getMainBannersAfterGroup(group, placement = "after") {
      return this.mainBanners.filter(
        (x) => x.positionAfterGroup == group && x.placement == placement
      );
    },

    getMiniBannersAfterGroup(group, placement = "after") {
      return this.miniBanners.filter(
        (x) => x.positionAfterGroup == group && x.placement == placement
      );
    },

    setTitle() {
      document.title = this.store.webstore?.title || this.store.appName;
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    imageBanners() {
      return this.banners ? this.banners.filter((x) => x.type == "image") : [];
    },

    logo() {
      return Store.logoURL();
    },

    categoryGridUI() {
      return this.$store.state.storeInfo.webstore?.categoryGridUI || 2;
    },

    productGridUI() {
      return this.$store.state.storeInfo.webstore?.productGridUI || 2;
    },
  },

  data() {
    return {
      loading: false,
      cloading: false,
      dloading: false,

      rploading: false,
      tploading: false,
      csploading: false,
      fploading: false,

      deals: [],
      banners: [],
      categories: [],

      miloading: false,
      mnloading: false,
      mainBanners: [],
      miniBanners: [],

      recentProducts: [],
      trendingProducts: [],
      comingSoonProducts: [],
      recommendedProducts: [],
      featuredProducts: [],

      groups: [],
      gloading: false,
      groupsObject: {
        items: [],
        pageNumber: 1,
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 50,
      },
    };
  },
};
