import User from '../../../services/user';

export default {
  name: 'Profile',

  created() {
    this.user = Object.assign({}, this.userInfo);
    this.referral = Object.assign({}, this.store.referralSettings);
  },

  methods: {
    save() {
      if (!this.$refs.profile || !this.$refs.profile.validate()) return;

      const object = {
        name: this.user.name,
        email: this.user.email
      };

      this.processing = true;
      return User
        .editUser(this.store._id, this.userInfo._id, object)
        .then(x => {
          console.log();
          if (x.data && x.data.ok) {
            this.$store.state.userInfo.name = object.name;
            this.$store.state.userInfo.email = object.email;
          }
          this.processing = this.editMode = false;
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        })
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    userInfo() {
      return this.$store.state.userInfo;
    }
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;
    return {
      loading: false,
      processing: false,
      editMode: false,

      user: {},
      referral: {},

      validationRules: {
        basicRule: [
          v => !!v || 'It is required'
        ],
        emailRule: [
          v => v && emailregex.test(v) || 'Enter a valid email'
        ]
      }
    }
  }
}