import User from '../../services/user';

import Address from '../address/address.vue';

export default {
  name: 'Addresses',

  props: [
    'vModel',
    'selectable'
  ],

  created() {
    if (!this.store._id) return;
    this.selected = this.vModel;
    this.fetchAddresses();
  },

  components: {
    Address
  },

  methods: {
    fetchAddresses(isNew = false) {
      this.$parent.aloading = true;
      return User
        .getAddresses(this.store._id, this.user._id)
        .then(x => {
          this.$store.state.addresses = x.data;
          if (isNew) this.selected = isNew;
          this.$parent.aloading = false;
          this.sortAddresses();
        })
        .catch(e => {
          console.log(e);
          this.$parent.aloading = false;
        });
    },

    sortAddresses() {
      if (this.selected) {
        this.$store.state.addresses.forEach(x => x.selected = x._id == this.selected);
        this.$store.state.addresses.sort((a, b) => a.selected < b.selected);
      }
    },

    editAddress(item) {
      this.$store.state.editedAddressItem = JSON.parse(JSON.stringify(item));
      this.$store.state.addressModal = true;
    },

    initAddressDelete(item) {
      this.$store.state.editedAddressItem = Object.assign({}, item);
      this.$store.state.confirmModal = true;
    },

    deleteAddress() {
      this.aprocessing = true;
      const aid = this.$store.state.editedAddressItem._id;
      return User
        .deleteAddress(aid)
        .then(() => {
          this.aprocessing = false;
          this.$store.state.confirmModal = false;
          this.fetchAddresses();
        })
        .catch(e => {
          console.log(e);
          this.aprocessing = true;
        });
    },

    isSelected(item) {
      return this.selected == item._id;
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    },

    addresses() {
      return this.$store.state.addresses;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  watch: {
    addressModified(n) {
      if (n) {
        this.fetchAddresses(n.isNew ? n._id : false);
        this.addressModified = false;
      }
    },

    selected(n) {
      this.$emit('update:vModel', n);
    }
  },

  data() {
    return {
      aprocessing: false,
      addressModified: false,

      selected: ''
    }
  }
}