import Vue from 'vue';

export default {
  getAddresses(_id, userId) {
    return Vue.axios.get(`user/list/addresses/${userId}/${_id}`, {ngProgress: false});
  },

  editUser(_id, userId, object) {
    return Vue.axios.put(`user/${_id}/${userId}`, object, {ngProgress: false});
  },

  addAddress(_id, userId, address) {
    return Vue.axios.post(`user/address/${userId}/${_id}`, address, {ngProgress: false});
  },

  editAddress(_id, address) {
    return Vue.axios.put(`user/address/${_id}`, address, {ngProgress: false});
  },

  deleteAddress(_id) {
    return Vue.axios.delete(`user/address/${_id}`, {ngProgress: false});
  },

  getWallet(_id, userId) {
    return Vue.axios.get(`wallet/${_id}/${userId}`, {ngProgress: false});
  },

  getWalletHistory(_id, walletId) {
    return Vue.axios.get(`wallet/history/${_id}/${walletId}`, {ngProgress: false});
  },

  rechargeWallet(_id, userId, recharge) {
    return Vue.axios.post(`wallet/add/${_id}/${userId}`, recharge, {ngProgress: false});
  },

  getMembership(_id, userId) {
    return Vue.axios.get(`user/membership/${_id}/${userId}`, {ngProgress: false});
  }
}