<template>
  <v-row>
    <v-col class="text-center">
      <div style="background: var(--primary); padding:5px; color: white; padding-bottom: 8px;">
        <small>Due to the COVID 19 epidemic, orders may be processed with a slight delay</small>
      </div>
    </v-col>
  </v-row>
</template>
<script lang="ts">
export default {
  name: 'TopBanner'
}
</script>