var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.banners && _vm.banners.length)?_c('section',[(_vm.type == 'main')?_c('v-row',[_c('v-col',[(!_vm.fullscreen)?_c('div',{staticClass:"mt-5"}):_vm._e(),_c('v-carousel',{attrs:{"cycle":"","show-arrows":false,"hide-delimiter-background":"","delimiter-icon":"mdi-minus","height":_vm.isMobile ? '145px' : '475px'}},_vm._l((_vm.banners),function(slide,i){return _c('v-carousel-item',{key:i,on:{"click":function($event){return _vm.redirectTo(slide)}}},[(slide.type == 'image')?_c('v-img',{class:['align-center', 
              _vm.fullscreen ? '' : 'rounded-lg',
              slide.contentAlignment == 'left' ? 'pl-12' : '', 
              slide.contentAlignment == 'center' ? 'text-center' : '',
              slide.contentAlignment == 'right' ? 'text-right pr-12' : '',
              slide.redirectTo ? 'link' : ''],attrs:{"height":!_vm.isMobile ? 475 : '',"src":_vm.getAssetURL(slide.file)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)},[(slide.showContent)?_c('div',{staticClass:"main-banner-content"},[_c('h1',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(slide.title)}}),_c('p',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(slide.description)}}),_c('div',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"nott",attrs:{"rounded":"","large":"","color":"primary"}},[_vm._v(_vm._s(slide.buttonText || 'Shop Now'))])],1)]):_vm._e()]):_vm._e()],1)}),1)],1)],1):_vm._e(),(_vm.type == 'mini')?_c('v-row',_vm._l((_vm.banners),function(slide,i){return _c('v-col',{key:i,attrs:{"cycle":"","sm":"6","cols":"12"},on:{"click":function($event){return _vm.redirectTo(slide)}}},[(slide.type == 'image')?_c('v-img',{class:['rounded-lg align-center', 
          !slide.contentAlignment || slide.contentAlignment == 'left' ? 'pl-8' : '', 
          slide.contentAlignment == 'center' ? 'text-center' : '',
          slide.contentAlignment == 'right' ? 'text-right pr-8' : '',
          slide.redirectTo ? 'link' : ''],attrs:{"src":_vm.getAssetURL(slide.file)},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)},[(slide.showContent)?_c('div',{staticClass:"main-banner-content"},[_c('h2',{domProps:{"innerHTML":_vm._s(slide.title)}}),_c('span',{domProps:{"innerHTML":_vm._s(slide.description)}}),_c('div',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"nott",attrs:{"rounded":"","small":"","color":"primary"}},[_vm._v(_vm._s(slide.buttonText || 'Shop Now'))])],1)]):_vm._e()]):_vm._e()],1)}),1):_vm._e(),(_vm.ismb)?_c('div',{staticClass:"mb-8"}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }