<template>
  <small><v-icon style="margin-top: -3px; font-size: 1.1rem;">mdi-information-variant</v-icon> Need Help? <span class="link text-primary" @click="openWhatsApp">Contact Us</span></small>
</template>
<script>
import Util from '../../services/util';

export default {
  methods: {
    openWhatsApp() {
      if (!this.wan) return;
      const wam = this.store.whatsappMessage;
      Util.openWhatsApp(this.wan, wam);
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    wan() {
      return this.store.whatsappNumber;
    },
  }
}
</script>