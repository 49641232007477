import _ from "underscore";

import router from "../../router";
import Store from "../../services/store";

export default {
  name: "Header",

  created() {
    this.fetchShop();
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    isMV() {
      return this.store.isMV;
    },

    logo() {
      return Store.logoURL();
    },

    userInfo() {
      return this.$store.state.userInfo;
    },

    wishlist() {
      return this.$store.state.wishlist;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    isSearch() {
      return this.$route.name == "search";
    },
  },

  methods: {
    signin() {
      this.$store.state.isSignin = true;
      this.$store.state.signupModal = true;
    },

    signup() {
      this.$store.state.isSignin = false;
      this.$store.state.signupModal = true;
    },

    fetchShop() {
      const sid = this.$store.state.storeInfo
        ? this.$store.state.storeInfo._id
        : false;
      if (!sid) return;
      this.fcategories = true;
      return Store.getCategoriesSubcategories(sid, false)
        .then((x) => {
          this.categories = x.data;
          this.fcategories = false;
        })
        .catch((e) => {
          console.log(e);
          this.fcategories = false;
        });
    },

    goto(type, auth = true) {
      this.hover = false;
      if (auth && !this.$store.state.authenticated) return this.signup();
      router.push("/" + type).catch(() => {});
    },

    gotocategory(category) {
      this.hover = false;
      this.$router
        .push({
          name: "category",
          params: { slug: category.slug || category._id },
        })
        .catch(() => {});
    },

    hideShopContainer() {
      if (this.hover) this.hover = false;
    },
  },

  watch: {
    gsearch: _.debounce(function (val) {
      this.$store.state.gsearch = val;
    }, 500),

    searchFocus(n) {
      if (n) this.$router.push({ name: "search" }).catch(() => {});
      if (this.isMobile) this.searchFocus = false;
    },
  },

  data() {
    return {
      gsearch: "",
      asearch: "",

      hover: false,
      categories: [],

      searchFocus: false,
      locationFocus: false,
    };
  },
};
