<template>
  <v-card flat :class="['product-card', isMobile ? 'mcat-card' : '']" @click="navigate">
    <v-carousel touchless cycle hide-delimiters interval="4000" :show-arrows="false" :height="isMobile ? 175 : 300">
      <v-carousel-item
        v-for="(item,i) in photos"
        :key="i" :src="getOriginalAssetURL(item, category.store)"
        :lazy-src="lazy"
        :alt="category.slug"></v-carousel-item>
    </v-carousel>

    <div style="padding: 1rem;">
      <v-row>
        <v-col class="vc">
          <CategoryItemContent :category="category"></CategoryItemContent>
        </v-col>
        <v-col cols="3" class="vc text-center">
          <v-btn fab outlined x-small color="primary"><v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import Store from '../../services/store';
import CategoryItemContent from '../../components/category-item-content/category-item-content.vue';

export default {
  name: 'CategoryItem',

  props: [
    'grid',
    'category',
    'isSearch',
    'iheight'
  ],

  components: {
    CategoryItemContent
  },

  methods: {
    getAssetURL(data) {
      return Store.getAssetURL(data);
    },

    getOriginalAssetURL(data, sid) {
      return Store.getOriginalAssetURL(data, sid);
    },

    navigate() {
      this.$parent.gsearch = '';
      this.$router.push({ name: 'category', params: {slug: this.category.slug || this.category._id}}).catch(() => {});
    },
  },

  computed: {
    logo() {
      return Store.logoURL();
    },

    photos() {
      let photos = this.category.photos;
      if (photos && photos.length) photos = photos.filter(x => x.includes('png'));
      if (!photos || !photos.length) return [''];
      return photos;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      lazy: require("@/assets/imgs/placeholder.png"),
    }
  }
}
</script>