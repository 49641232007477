import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

import store from "../store";

//views
import Product from "../views/product/product.vue";
import Category from "../views/category/category.vue";
import Categories from "../views/categories/categories.vue";

import Deal from "../views/deal/deal.vue";
import Collection from "../views/collection/collection.vue";

import Checkout from "../views/checkout/checkout.vue";
import CheckoutSuccess from "../views/checkout/success/success.vue";

import Account from "../views/account/account.vue";
import Search from "../views/search/search.vue";

import PathNotFound from "../views/page-not-found/page-not-found.vue";

import AboutUs from "../views/about-us/about-us.vue";
import ContactUs from "../views/contact-us/contact-us.vue";
import ReturnPolicy from "../views/return-policy/return-policy.vue";
import RefundPolicy from "../views/refund-policy/refund-policy.vue";
import ShippingPolicy from "../views/shipping-policy/shipping-policy.vue";
import CancellationPolicy from "../views/cancellation-policy/cancellation-policy.vue";

import PrivacyPolicy from "../views/privacy-policy/privacy-policy.vue";
import TermsAndConditions from "../views/terms-and-conditions/terms-and-conditions.vue";
import DeepLink from "../views/deeplink/deeplink.vue";

NProgress.configure({ showSpinner: false });
Vue.use(VueRouter);

const routes = [
  {
    path: "/categories",
    name: "categories",
    component: Categories,
    meta: {
      requiresAuth: false,
      breadcrumb: [
        { text: "Home", disabled: false, href: "/" },
        { text: "Categories", disabled: true },
      ],
    },
  },
  {
    path: "/deal/:slug",
    name: "deal",
    component: Deal,
    meta: {
      requiresAuth: false,
      breadcrumb: [{ text: "Home", disabled: false, href: "/" }],
    },
  },
  {
    path: "/collection/:slug",
    name: "collection",
    component: Collection,
    meta: {
      requiresAuth: false,
      breadcrumb: [{ text: "Home", disabled: false, href: "/" }],
    },
  },
  {
    path: "/category/:slug",
    name: "category",
    component: Category,
    meta: {
      requiresAuth: false,
      breadcrumb: [
        { text: "Home", disabled: false, href: "/" },
        { text: "Categories", disabled: false, href: "/categories" },
      ],
    },
  },
  {
    path: "/product/:slug",
    name: "product",
    component: Product,
    meta: {
      requiresAuth: false,
      breadcrumb: [
        { text: "Home", disabled: false, href: "/" },
        { text: "Categories", disabled: false, href: "/categories" },
      ],
    },
  },
  {
    path: "/checkout/:slug(cart|address|payment)",
    name: "checkout",
    component: Checkout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout/subscription/:slug(address|payment)?",
    name: "checkout-subscription",
    component: Checkout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout/subscription/success",
    name: "checkout-subscription-success",
    component: CheckoutSuccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout/success",
    name: "checkout-success",
    component: CheckoutSuccess,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    meta: {
      requiresAuth: true,
    },
    redirect: { name: "account-slug", params: { slug: "profile" } },
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/account/:slug(profile|orders|addresses|wishlist|wallet|subscriptions|notifications)",
    name: "account-slug",
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/return-policy",
    name: "return-policy",
    component: ReturnPolicy,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/refund-policy",
    name: "refund-policy",
    component: RefundPolicy,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/shipping-policy",
    name: "shipping-policy",
    component: ShippingPolicy,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/cancellation-policy",
    name: "cancellation-policy",
    component: CancellationPolicy,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditions,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/a/:uuid",
    name: "apps-deeplink",
    component: DeepLink,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:slug/p/:uuid",
    name: "product-deeplink",
    component: DeepLink,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:slug/r/:uuid",
    name: "referral-deeplink",
    component: DeepLink,
    meta: {
      requiresAuth: true,
    },
  },

  { path: "*", component: PathNotFound },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

const processAuthVerification = (next) => {
  const token = store.state.token;
  Vue.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  store
    .dispatch("verify")
    .then(() => next())
    .catch(() => (store.state.signupModal = true));
};

//auth guard
router.beforeEach((to, from, next) => {
  //for real-time if user deletes token from storage
  if (!localStorage.getItem("token")) store.commit("authError");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      store.state.signupModal = true;
      router.push("/").catch(() => {});
      next(false);
    } else {
      if (!store.state.userInfo._id) processAuthVerification(next);
      else next();
    }
  } else {
    //if user forceRefresh the page
    if (store.getters.isAuthenticated && !store.state.userInfo.name)
      processAuthVerification(next);
    else next();
  }
});

/*as we've added loading for http now*/
router.beforeResolve((to, from, next) => {
  //if (to.name) NProgress.start()
  next();
});

router.afterEach(() => NProgress.done());

export default router;
