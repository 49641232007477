import $ from "jquery";
import Success from "../success/success.vue";

import Util from "../../../services/util";
import Order from "../../../services/order";
import Store from "../../../services/store";
import User from "../../../services/user";
import Subscription from "../../../services/subscription";

export default {
  name: "SubscriptionBilling",

  props: [
    "order",
    "slug",
    "errors",
    "wallet",
    "completed",
    "membership",
    "subscription",
  ],

  components: {
    Success,
  },

  methods: {
    scrollTop() {
      $("html, body").animate({ scrollTop: 0 }, 250);
    },

    navigate(to) {
      this.$router
        .push({ name: "checkout-subscription", params: { slug: to } })
        .catch(() => {});
    },

    nextstep() {
      if (!this.slug) {
        if (!this.subscription.endDate) this.scrollTop();
        else {
          const slug = "address";
          this.$emit("update:slug", slug);
          if (!this.completed.includes("subscription"))
            this.completed.push("subscription");
          this.navigate(slug);
        }
      } else if (this.slug == "address") {
        const ss = this.slots;
        const cs = this.communities;

        //community, slot, delivery date, deliveryMode, deliveryAddress validation
        const idsm = this.store.isDeliverySlotSelectionMandatoryInSubscription;
        const icsm = this.store.isCommunitySelectionMandatoryInSubscription;

        if (cs && cs.length && icsm && !this.order.community)
          this.errors.community = true;
        if (ss && ss.length && idsm && !this.order.slot)
          this.errors.slot = true;
        if (!this.order.deliveryMode) this.errors.deliveryMode = true;
        if (!this.order.deliveryAddress) this.errors.deliveryAddress = true;

        if (
          this.errors.community ||
          this.errors.slot ||
          this.errors.deliveryMode ||
          this.errors.deliveryAddress
        ) {
          this.scrollTop();
          this.navigate("address");
          setTimeout(() => {
            this.errors.slot = false;
            this.errors.community = false;
            this.errors.deliveryMode = false;
            this.errors.deliveryAddress = false;
          }, 2500);
        } else {
          const slug = "payment";
          this.$emit("update:slug", slug);
          if (!this.completed.includes("address"))
            this.completed.push("address");
          this.navigate(slug);
        }
      } else {
        this.initProcess();
      }
    },

    onlinePaymentRequired() {
      let required = false;
      const isWalletBalanceLow =
        !this.wallet ||
        !this.wallet.balance ||
        this.wallet.balance < this.store.minimumWalletBalanceForSubscription;

      if (this.subscription.isPrepayment) required = true;
      else if (!this.subscription.isPostpaid && isWalletBalanceLow) {
        let toDeduct = 0;
        if (this.wallet.balance)
          toDeduct = parseFloat(this.wallet.balance.toFixed(2));
        this.amountToAdd =
          parseFloat(this.store.minimumWalletBalanceForSubscription) - toDeduct;
        required = true;
      }

      return required;
    },

    initProcess() {
      const paymentCheck = this.onlinePaymentRequired();
      if (paymentCheck) {
        if (this.payment) this.processOnlinePayment();
        else this.fetchPaymentSetting();
      } else {
        this.activateSubscription();
      }
    },

    fetchPaymentSetting() {
      this.ploading = true;
      return Store.getPaymentSettings(this.store._id).then(
        (res) => {
          const x = res.data;
          if (x && x.isActive) {
            this.payment = x;
          }

          this.ploading = false;
          this.processOnlinePayment();
        },
        (e) => {
          console.log(e);
          this.ploading = false;
          this.mainerr = true;
        }
      );
    },

    processOnlinePayment() {
      this.oloading = true;

      const amount = this.razorpayOptions().amount;
      const currencyCode = this.razorpayOptions().currencyCode;
      const options = { amount: amount, currencyCode: currencyCode };

      return Order.createRazorpayOrder(this.store._id, options)
        .then((r) => {
          const res = r.data;
          this.payOnline(res.orderId);
          this.oloading = false;
        })
        .catch(() => {
          console.log("could not generate rzp oid");
          this.oloading = false;
          this.payOnline();
        });
    },

    razorpayOptions() {
      const isPrepayment = this.subscription.isPrepayment;
      const ammountToPay = isPrepayment
        ? this.subscriptionTotal
        : this.amountToAdd;
      const amount = parseFloat((ammountToPay * 100).toFixed(2));
      const currencyCode =
        this.store.userCountry && this.store.userCountry.currencyCode
          ? this.store.userCountry.currencyCode
          : "INR";
      return { amount: amount, currencyCode: currencyCode };
    },

    payOnline(orderId = "") {
      const isPrepayment = this.subscription.isPrepayment;
      const amount = this.razorpayOptions().amount;
      const currencyCode = this.razorpayOptions().currencyCode;

      const transText = isPrepayment
        ? "Subscription Payment Transaction"
        : "Wallet Recharge";
      const options = Util.getRazorpayOptions(
        currencyCode,
        amount,
        transText,
        this.payment && this.payment.key ? this.payment.key : "",
        orderId
      );

      const newthis = this;
      options.handler = function (response) {
        const paymentId = response.razorpay_payment_id;
        isPrepayment
          ? newthis.subscriptionSuccessCallback(paymentId)
          : newthis.walletRechargeSuccessCallback(paymentId);
      };
      const razorpay = new window.Razorpay(options);
      razorpay.on("payment.failed", function (response) {
        if (response && response.error && response.error.description) {
          newthis.errorCallBack(response.error.description);
        }
      });
      razorpay.open();
    },

    throwError() {
      this.errorCallBack("Oops! Something went wrong; try later.");
    },

    errorCallBack(text) {
      this.$store.state.snackbar.text =
        text || "Something went wrong, try again";
      this.$store.state.snackbar.timeout = 3000;
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    subscriptionSuccessCallback(paymentId) {
      this.createSubscription(paymentId);
    },

    walletRechargeSuccessCallback(paymentId) {
      if (!paymentId) return this.errorCallBack();
      this.processing = true;

      const recharge = {
        amount: parseFloat(this.amountToAdd),
        paymentId: paymentId,
      };

      return User.rechargeWallet(this.store._id, this.user._id, recharge)
        .then(() => {
          this.amountToAdd = 0;
          this.processing = false;
          this.activateSubscription();
          this.$parent.$parent.fetchWallet();
        })
        .catch(() => {
          this.throwError();
          this.amountToAdd = 0;
          this.processing = false;
        });
    },

    activateSubscription() {
      this.ascModal = true;
    },

    processSubscription() {
      this.createSubscription();
    },

    successMessage(text) {
      this.$store.state.snackbar.text =
        text || "Payment Done! Activating your subscription...";
      this.$store.state.snackbar.timeout = 3000;
      this.$store.state.snackbar.color = "success";
      this.$store.state.snackbar.show = true;
    },

    createSubscription(paymentId = "") {
      if (paymentId) this.successMessage();

      const subscription = Object.assign({}, this.subscription);
      subscription.total = this.subscriptionTotal;
      subscription.startDate = subscription.startDate.split("T")[0];
      subscription.endDate = subscription.endDate.split("T")[0];
      subscription.currencyCode = this.razorpayOptions().currencyCode;
      subscription.deliveryMode = this.order.deliveryMode;

      subscription.product = this.subscription.product._id;
      subscription.price = this.subscription.product.price;
      subscription.size = this.subscription.product.size;
      subscription.unit = this.subscription.product.unit;
      subscription.gst = this.subscription.product.gst;

      if (paymentId) subscription.paymentId = paymentId;
      if (this.order.slot) subscription.slot = this.order.slot;
      if (this.order.notes) subscription.notes = this.order.notes;
      if (this.order.community) subscription.community = this.order.community;
      if (this.order.deliveryAddress)
        subscription.address = this.order.deliveryAddress;
      if (!subscription.coupon) delete subscription.coupon;
      if (!subscription.discountOn) delete subscription.discountOn;

      this.processing = true;
      return Subscription.createSubscription(this.store._id, subscription)
        .then((x) => {
          this.$store.state.subscriptionCart.item = {};
          if (x.data) this.$store.state.oid = x.data.sid;
          this.$router
            .push({ name: "checkout-subscription-success" })
            .catch(() => {});
          this.processing = false;
        })
        .catch(() => {
          this.processing = false;
          this.throwError();
        });
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    },

    locations() {
      return this.$parent.locations;
    },

    communities() {
      return this.$parent.communities;
    },

    slots() {
      return this.$parent.slots;
    },

    wan() {
      return this.store.whatsappNumber;
    },

    //subscription total
    subscriptionSubtotalTotal() {
      const total = this.subscription.product.price * this.subscription.qty;
      this.subscription.subscriptionSubtotalTotal = total;
      return total;
    },

    subscriptionDeliveryCharge() {
      const baseDC = this.store.subscriptionDeliveryCharge;
      const baseDFA = this.store.subscriptionDeliveryFreeAbove;

      let deliveryCharge = 0;
      if (baseDC && this.order.deliveryMode === "home-delivery") {
        if (this.subscription.product.price * this.subscription.qty <= baseDFA)
          deliveryCharge = baseDC;
      }
      this.subscription.deliveryCharge = deliveryCharge;
      this.subscription.subscriptionDeliveryCharge = deliveryCharge;

      return deliveryCharge;
    },

    perDeliveryTotal() {
      const pdt = this.subscription.product.price * this.subscription.qty;
      this.subscription.perDeliveryTotal =
        pdt + this.subscriptionDeliveryCharge;
      this.subscription.perDeliveryTotalWithoutDC = pdt;
      return pdt;
    },

    subscriptionTotal() {
      let totalAmount =
        this.subscription.schedule.length *
        (this.subscription.product.price * this.subscription.qty +
          this.subscriptionDeliveryCharge);

      const firstFive =
        this.subscription.schedule.length > 5
          ? 5
          : this.subscription.schedule.length;
      const firstTen =
        this.subscription.schedule.length > 10
          ? 10
          : this.subscription.schedule.length;

      if (this.subscription.discount) {
        if (!this.subscription.isPrepayment) {
          if (this.subscription.discountOn == "ao")
            totalAmount =
              totalAmount -
              this.subscription.discount * this.subscription.schedule.length;
          else if (this.subscription.discountOn == "fo5")
            totalAmount = totalAmount - this.subscription.discount * firstFive;
          else if (this.subscription.discountOn == "fo10")
            totalAmount = totalAmount - this.subscription.discount * firstTen;
          else totalAmount = totalAmount - this.subscription.discount;
        } else {
          totalAmount = totalAmount - this.subscription.discount;
        }
      }

      this.subscription.subscriptionTotal = totalAmount;
      this.subscription.totalWithoutDC =
        this.subscription.schedule.length *
        (this.subscription.product.price * this.subscription.qty);

      return totalAmount;
    },

    totalDeliveries() {
      const totalDeliveries = this.subscription.schedule.length;
      this.subscription.totalDeliveries = totalDeliveries;
      return totalDeliveries;
    },
  },

  data() {
    return {
      oloading: false,
      cpuModal: false,
      showWallet: true,
      aoprocessing: false,

      processing: false,
      ploading: false,
      payment: false,
      ascModal: false,

      amountToAdd: 0,
    };
  },
};
