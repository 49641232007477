import $ from 'jquery';
import moment from 'moment';

import Util from '../../../services/util';
import Store from '../../../services/store';
import Subscription from '../../../services/subscription';
import NotFound from '../../../components/not-found/not-found.vue';

export default {

  created() {
    this.scrollTop();
    if (!this.user._id) return this.$router.push({ name: 'account' }).catch(() => {});
    this.fetchSubscriptions();
  },

  components: {
    NotFound
  },

  methods: {
    scrollTop() {
      $('html, body').animate({ scrollTop: 0 }, 250);
    },

    fetchSubscriptions() {
      this.loading = true;

      let status = 'active';
      if (this.tab == 1) status = 'paused';
      else if (this.tab == 2) status = 'completed';
      else if (this.tab == 3) status = 'cancelled';

      return Subscription
        .getSubscriptions(this.store._id, this.user._id, status)
        .then(x => {
          const subscriptions = x.data;

          subscriptions.forEach(x => {
            x.tab = 0;
            x.expanded = false;

            const es = this.editedSubscription;
            if (es && es._id && es._id == x._id) {
              x.tab = 1;
              x.expanded = 0;
            }
          });

          this.subscriptions = subscriptions;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    getName(sub) {
      return sub.address ? sub.address.name : (sub.user ? (sub.user.name || '-') : '-')
    },

    getCallingCode(item) {
      let cc = this.$store.state.country.callingCode || '91';
      if (item.address && item.address.callingCode) cc = item.address.callingCode;
      else if (!item.address && item.user && item.user.callingCode) cc = item.user.callingCode;
      return cc;
    },

    formatDate(date, format = 'MMM D, h:mm A') {
      return moment(date).format(format);
    },

    canCancel(sub) {
      return sub.status == 'new';
    },

    openWhatsApp(sub) {
      if (!this.wan) return;
      const wam = 'SID: *'+sub.sid+'*';
      Util.openWhatsApp(this.wan, wam);
    },

    navigate(address) {
      const url = `https://www.google.com/maps/search/?api=1&query=${address.lat},${address.long}`;
      window.open(url, '_system');
    },

    getAssetURL(data, sid) {
      return Store.getAssetURL(data, sid);
    },

    initCancel(sub) {
      this.editedItem = sub;
      this.cancelModal = true;
    },

    cancelSubscription() {
      this.processing = true;
      return Subscription
        .cancelSubscription(this.editedItem._id)
        .then(() => {
          this.processing = this.cancelModal = false;
          this.fetchSubscriptions();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        });
    },

    indexedSchedule(sub) {
      return sub.schedule.map((x, index) => {
        x.index = (index + 1)+'.';
        return x;
      });
    },

    skipConfirmation(subscription, schedule) {
      this.editedSubscription = subscription;
      this.editedSchedule = schedule;
      this.skipModal = true;
    },

    undoConfirmation(subscription, schedule) {
      this.editedSubscription = subscription;
      this.editedSchedule = schedule;
      this.undoModal = true;
    },

    removeConfirmation(subscription, schedule) {
      this.editedSubscription = subscription;
      this.editedSchedule = schedule;
      this.removeModal = true;
    },

    skipSchedule(type) {
      const object = { scheduleId: this.editedSchedule._id, type: type };
      this.processing = true;
      return Subscription
        .skipSchedule(this.editedSubscription._id, object)
        .then(() => {
          this.processing = this.skipModal = false;
          this.fetchSubscriptions();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        });
    },

    undoSchedule() {
      const object = { scheduleId: this.editedSchedule._id };
      this.processing = true;
      return Subscription
        .undoSchedule(this.editedSubscription._id, object)
        .then(() => {
          this.processing = this.undoModal = false;
          this.fetchSubscriptions();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        });
    },

    removeSchedule() {
      const object = { scheduleId: this.editedSchedule._id };
      this.processing = true;
      return Subscription
        .removeSchedule(this.editedSubscription._id, object)
        .then(() => {
          this.processing = this.removeModal = false;
          this.fetchSubscriptions();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        });
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    },

    wan() {
      return this.store.whatsappNumber;
    },

    logo() {
      return Store.logoURL();
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  watch: {
    tab() { this.fetchSubscriptions(); }
  },

  data() {
    return {
      subscriptions: [],
      loading: false,
      processing: false,
      tab: 0,

      editedItem: {},

      skipModal: false,
      undoModal: false,
      removeModal: false,
      cancelModal: false,

      editedSubscription: {},
      editedSchedule: {},

      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Day, Date', value: 'date' },
        { text: 'Period', value: 'period' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false},
      ]
    }
  }
}