import namedColors from "color-name-list";

import Util from "../../services/util";
import Store from "../../services/store";
import Cart from "../../services/cart";
import Wishlist from "../../services/wishlist";

export default {
  name: "ProductItem",

  props: [
    "iheight",
    "product",
    "hideBorder",
    "singleImage",
    "disableHover",
    "isSearch",
    "itemClicked",
  ],

  methods: {
    getAssetURL(data, sid) {
      return Store.getAssetURL(data, sid);
    },

    getOriginalAssetURL(data, sid) {
      return Store.getOriginalAssetURL(data, sid);
    },

    getPrice(product) {
      return Cart.getPrice(product);
    },

    getSaving(product) {
      return product.marketPrice - product.price;
    },

    processWishlist(product) {
      Wishlist.processWishlist(this.store._id, product);
    },

    processPreview() {
      this.previewModal = true;
      this.fetchProduct(this.product);
    },

    fetchProduct(product) {
      this.processing = true;
      return Store.getProduct(product._id, false)
        .then((res) => {
          this.pp = res.data;
          this.findDifferentSizes();
          this.findDifferentColors();
          this.processing = false;
        })
        .catch((err) => {
          console.log(err);
          this.processing = this.previewModal = false;
        });
    },

    findDifferentColors() {
      const colors = [];
      if (this.pp.color) colors.push(this.pp.color);
      if (this.pp.children) {
        this.pp.children.forEach((x) => {
          if (!colors.includes(x.color)) colors.push(x.color);
        });
      }
      this.colors = colors.filter((x) => x);
    },

    findDifferentSizes() {
      const sizes = [];
      if (this.pp.size) sizes.push({ size: this.pp.size, unit: this.pp.unit });
      if (this.pp.children) {
        this.pp.children.forEach((x) => {
          if (!sizes.find((s) => s.size == x.size && s.unit == x.unit))
            sizes.push({ size: x.size, unit: x.unit });
        });
      }
      this.sizes = sizes;
    },

    getColorName() {
      if (!this.selectedVariant.color) return "";
      const color = namedColors.find(
        (color) => color.hex == this.selectedVariant.color.toLowerCase()
      );
      if (color) return color.name;
      return this.selectedVariant.color;
    },

    navigate() {
      if (this.itemClicked) this.itemClicked();

      const isCMV = this.store.isCMV;
      this.$router
        .push({
          name: "product",
          params: {
            slug: isCMV
              ? this.product._id
              : this.product.slug || this.product._id,
          },
        })
        .catch(() => {});
    },

    generateSlug(string) {
      return Util.generateSlug(string);
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    },

    isAuthenticated() {
      return this.$store.state.authenticated;
    },

    logo() {
      return Store.logoURL();
    },

    isInWishlist() {
      return Wishlist.isInWishlist(this.product._id);
    },

    processingWishlist() {
      return Wishlist.isProcessing();
    },

    photos() {
      let photos = this.product.photos;
      if (photos && photos.length)
        photos = photos.filter((x) => x && x.includes("png"));
      if (!photos || !photos.length) return [""];
      return photos;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    height() {
      return this.isMobile ? 150 : this.iheight || 315;
    },

    hidePricing() {
      return (
        this.store.hideProductPriceIfUserNotApproved &&
        (!this.isAuthenticated ||
          (this.user && this.user.profile && !this.user.profile.isApproved))
      );
    },
  },

  data() {
    return {
      processing: false,
      previewModal: false,

      pp: {},
      selectedVariant: {},
      lazy: require("@/assets/imgs/placeholder.png"),

      units: [],
      sizes: [],
      colors: [],
    };
  },
};
