import $ from "jquery";

import Profile from "./profile/profile.vue";
import Wallet from "./wallet/wallet.vue";
import Orders from "./orders/orders.vue";
import Wishlist from "./wishlist/wishlist.vue";
import Addresses from "./addresses/addresses.vue";
import Subscriptions from "./subscriptions/subscriptions.vue";

import Util from "../../services/util";
//{ title: 'Wallet', icon: 'mdi-credit-card', url: 'wallet' },

export default {
  created() {
    this.scrollTop();
    this.slug = this.$route.params.slug;
    if (this.slug) this.setSelected();

    if (this.slug == "wallet" && this.store.hideWallet)
      this.menus = this.menus.filter((x) => x.title !== "Wallet");

    if (
      (Util.isPremiumPlan(this.store.plan) ||
        Util.isSinglevendorPremiumPlan(this.store.plan)) &&
      this.store.subscriptionEnabled
    ) {
      this.menus.push({
        title: "Subscriptions",
        icon: "mdi-clock",
        url: "subscriptions",
      });
    } else if (this.slug == "subscriptions") {
      this.$router.push({ name: "account" }).catch(() => {});
    }
  },

  components: {
    Profile,
    Wallet,
    Orders,
    Wishlist,
    Addresses,
    Subscriptions,
  },

  methods: {
    scrollTop() {
      $("html, body").animate({ scrollTop: 0 }, 250);
    },

    goto(item) {
      const object = {};

      if (!item.url) return;
      object.name = "account-slug";
      object.params = { slug: item.url };

      this.slug = item.url;
      this.$router.push(object).catch(() => {});
    },

    itemClicked(item) {
      if (item.action && item.url == "whatsapp") {
        const wan = this.store.whatsappNumber;
        const wam = this.store.whatsappMessage;
        Util.openWhatsApp(wan, wam);
      } else if (item.url == "logout") {
        this.$store.commit("logout");
      }
    },

    setSelected() {
      this.menus.forEach((x, i) => {
        if (x.url == this.slug) this.selected = i;
      });
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    gmenus() {
      return this.menus2.filter((x) => x.url);
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  watch: {
    "$route.params"(n) {
      if (!n || n.slug) {
        this.slug = n.slug;
        this.setSelected();
      }
    },
  },

  data() {
    return {
      slug: "",

      selected: 0,

      menus: [
        { title: "Profile", icon: "mdi-account", url: "profile" },
        { title: "Orders", icon: "mdi-cart", url: "orders" },
        { title: "Wishlist", icon: "mdi-heart", url: "wishlist" },
        { title: "Addresses", icon: "mdi-map-marker", url: "addresses" },
      ],

      menus2: [
        {
          title: "iOS",
          icon: "mdi-apple",
          url: this.$store.state.storeInfo.iosURL,
        },
        {
          title: "Android",
          icon: "mdi-android",
          url: this.$store.state.storeInfo.androidURL,
        },
        {
          title: "Privacy Policy",
          icon: "mdi-file-document",
          url: this.$store.state.storeInfo.privacyPolicyURL,
        },
        {
          title: "Terms & Conditions",
          icon: "mdi-file-document-outline",
          url: this.$store.state.storeInfo.termsConditionsURL,
        },
      ],

      menus3: [
        {
          title: "Help",
          icon: "mdi-whatsapp",
          url: "whatsapp",
          action: !!this.$store.state.storeInfo.whatsappNumber,
        },
        { title: "Logout", icon: "mdi-logout", url: "logout", action: true },
      ],
    };
  },
};
