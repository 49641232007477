<template>
  <section>
    <h4><b>{{ category.name }}</b></h4>
    <small v-if="category.children">
      {{category.children }} {{ (category.children == 1 ? 'subcategory' : 'subcategories') }}
    </small>
    <small v-else-if="!category.children && category.products >= 0">
      {{category.products }} {{ (category.products == 1 ? 'item' : 'items') }}
    </small>
  </section>
</template>
<script>
export default {
  name: 'CategoryItemContent',

  props: [
    'large',
    'category'
  ]
}
</script>
