import moment from 'moment';


import Cart from '../../../services/cart';
import Order from '../../../services/order';
import Store from '../../../services/store';

import BillingComponent from '../billing/billing.vue';
import CouponsComponent from '../coupons/coupons.vue';
import SubscriptionBillingComponent from '../subscription-billing/subscription-billing.vue';

export default {
  name: 'PaymentComponent',

  created() {
    if (!this.store._id) return;
    if (!this.isSubscription) this.processAbandonedOrder();
  },

  components: {
    BillingComponent,
    CouponsComponent,
    SubscriptionBillingComponent
  },

  props: [
    'enums',
    'order',
    'items',
    'errors',
    'completed',
    'membership',
    'subscription',
    'userMembership'
  ],

  methods: {
    getAssetURL(data, sid) {
      return Store.getAssetURL(data, sid);
    },

    initiateClearCart() {
      this.$store.state.cccModal = true;
    },

    getSaving(item) {
      return item.marketPrice - item.price;
    },

    itemTotal(item) {
      return parseFloat((item.qty * item.price).toFixed(2));
    },

    displayCoupons() {
      this.$store.state.couponsModal = true;
    },

    formatDate(date, format = 'lll') {
      return moment(date).format(format);
    },

    customPay() {
      window.open(this.store.customPaymentURL, '_system');
    },

    processAbandonedOrder() {
      if (this.order.aoid) return;
      const order = {
        type: 'website',
        items: this.items,
        amount: Cart.getTotal(),
        currencyCode: this.currencyCode
      }

      this.aoprocessing = true;
      return Order
        .placeAbandonedOrder(this.store._id, order)
        .then(res => {
          const x = res.data;
          if (x && x.ok && x._id) this.order.aoid = x._id;
          this.aoprocessing = false;
        })
        .catch(e => {
          console.log(e);
          this.aoprocessing = false;
        });
    },

    resetDiscountCoupon() {
      this.order.coupon = '';
      this.order.discount = this.order.cashback = 0;
      this.order.isCashback = this.order.isDeliveryFree = this.cerr = false;

      this.subscription.coupon = this.subscription.discountOn = '';
      this.subscription.discount = this.subscription.cashback = 0;
    },

    applyCoupon() {
      this.resetDiscountCoupon();

      if (this.$refs && !this.$refs.cform.validate()) return;
  
      if (!this.order.paymentMethod) {
        this.$parent.scrollTop();
        this.errors.invalidPM = true;
        setTimeout(() => this.errors.invalidPM = false, 2500);
        return;
      }
  
      let couponObj = {
        code: this.order.couponCode, 
        paymentMethod: this.order.paymentMethod, 
        amount: Cart.getTotal(),
      };

      if (this.isSubscription) {
        couponObj = {
          code: this.order.couponCode, 
          subscriptionPerDeliveryAmount: this.subscription.perDeliveryTotalWithoutDC,
          subscriptionTotalAmount: this.subscription.totalWithoutDC,
          totalDeliveries: this.subscription.totalDeliveries,
          isPrepayment: this.subscription.isPrepayment,
          deliveryCharge: this.subscription.deliveryCharge
        }
      }
  
      this.cloading = true;
      return Order
        .validateCoupon(this.store._id, couponObj, this.isSubscription)
        .then(r => {
          const x = r.data;
          if (x.status === 403) {
            this.cerr = x.description || x.message;
            setTimeout(() => this.cerr = false, 3000);
          } else {
            this.order.isCashback = x.isCashback;
            this.order.isDeliveryFree = x.isDeliveryFree;
            this.order.coupon = x.coupon;
            if (!x.isCashback && !x.isDeliveryFree) this.order.discount = x.discount;
            else if (x.isCashback && !x.isDeliveryFree) this.order.cashback = x.discount;

            //for - subscriptions
            if (this.isSubscription) { 
              this.subscription.coupon = x.coupon;
              this.subscription.isCashback = x.isCashback;
              this.subscription.discountOn = x.discountOn;
              this.subscription.isDeliveryFree = x.isDeliveryFree;
              if (!x.isCashback) this.subscription.discount = x.discount;
              else if (x.isCashback) this.subscription.cashback = x.discount;
            }
          }
          this.cloading = false;
        })
        .catch(e => {
          console.log(e);
          this.cloading = false;
          if (e.status === 403) {
            this.cerr = 'Invalid Coupon Code';
            setTimeout(() => this.cerr = false, 3000);
          }
        });
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    logo() {
      return Store.logoURL();
    },

    slots() {
      return this.$parent.slots;
    },

    communities() {
      return this.$parent.communities;
    },

    paymentMethods() {
      const dm = this.order.deliveryMode;
      let pm = this.enums.paymentMethods;

      if (dm == 'home-delivery') pm = pm.filter(x => x._id != 'PAS');
      else pm = pm.filter(x => x._id != 'COD');

      return pm;
    },

    bankAccount() {
      return this.$parent.bankAccount;
    },

    wallet() {
      return this.$parent.wallet;
    },

    currencyCode() {
      return this.$parent.currencyCode;
    },

    isSubscription() {
      return this.$parent.isSubscription;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  watch: {
    'order.paymentMethod'() {
      this.resetDiscountCoupon();
    }
  },

  data() {
    return {
      showWallet: false,
      showItemsInfo: true,
      showDeliveryInfo: true,
      showPaymentMethods: true,

      showCoupons: false,
      showSubsInfo: true,

      isCashback: false,
      isDeliveryFree: false,

      dmObj: false,
      slotObj: false,
      communityObj: false,

      cerr: false,
      cloading: false,
      aoprocessing: false,

      validationRules: {
        basicRule: [
          v => !!v || 'It can not be empty'
        ]
      }
    }
  }
}