import moment from 'moment';

import BillingComponent from '../billing/billing.vue';
import SubscriptionBillingComponent from '../subscription-billing/subscription-billing.vue';

import AddressList from '../../../components/address-list/address-list.vue';

export default {
  name: 'AddressComponent',

  created() {
    if (!this.isSubscription) this.processDistanceWiseSettings();
  },

  components: {
    AddressList,
    BillingComponent,
    SubscriptionBillingComponent
  },

  props: [
    'items',
    'order',
    'enums',
    'errors',
    'completed',
    'membership',
    'subscription'
  ],

  methods: {
    addAddress() {
      this.$store.state.editedAddressItem = {};
      this.$store.state.addressModal = true;
    },

    formatDate(date, format = 'lll') {
      return moment(date).format(format);
    },

    processDistanceWiseSettings() {
      if (this.order.dwsProcessed) return;

      if (this.store.placeId && this.store.distanceWiseDCEnabled) {
        //if already awailable lat long
        if (this.store.lat && this.store.long) {
          this.spInfo.lat = this.store.lat;
          this.spInfo.long = this.store.long;
        } else {
          //calculate pickup location lat/long
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ placeId: this.store.placeId }, (results) => {
            this.spInfo.lat = results[0].geometry.location.lat();
            this.spInfo.long = results[0].geometry.location.lng();
          });
        }

        this.calculateDistance();
      }
      this.order.dwsProcessed = true;
    },

    calculateDistance() {
      this.order.totalDistance = 0;
      const daObj = this.order.daObject;
      if (!daObj || !daObj.lat || !this.spInfo.lat) return;
      const cd = this.$store.state.calculatedDistances;
      const calculated = cd && cd.length ? cd.find(x => x.lat == daObj.lat && x.long == daObj.long) : false;
      if (calculated) return this.order.totalDistance = calculated.distance;

      const l1 = { lat: this.spInfo.lat, long: this.spInfo.long };
      const l2 = { lat: daObj.lat, long: daObj.long };
      const origins = [{ 'lat': l1.lat, 'lng': l1.long }];
      const destinations = [{ 'lat': l2.lat, 'lng': l2.long }];
      const service = new window.google.maps.DistanceMatrixService();
      const newthis = this;

      service.getDistanceMatrix({
        origins: origins,
        destinations: destinations,
        travelMode: 'DRIVING',
      }, function (result, status) {
        if (status == 'OK' && result.rows && result.rows[0]) {
          const distanceObj =  result.rows[0].elements[0].distance;
          const distanceInKM = distanceObj.value / 1000;
          const distance = Math.round(distanceInKM * 100 + Number.EPSILON) / 100;
          newthis.order.totalDistance = distance;
          newthis.$store.state.calculatedDistances.push({ lat: l2.lat, long: l2.long, distance: distance });
        }
      });
    },

    isSelected(item) {
      return this.order.deliveryAddress == item._id;
    },

    selectAddress(item) {
      this.order.deliveryAddress == item._id;
    }
  },

  computed: {
    slots() {
      if (this.order.deliveryDate) {
        const selectedDate = moment(this.order.deliveryDate).format('YYYY-MM-DD');
        const todayDate = moment().format('YYYY-MM-DD');
        if (selectedDate != todayDate) return this.allSlots;
      }
      return this.$parent.slots;
    },

    allSlots() {
      return this.$parent.allSlots;
    },

    communities() {
      return this.$parent.communities;
    },

    store() {
      return this.$store.state.storeInfo;
    },

    userInfo() {
      return this.$store.state.userInfo;
    },

    addresses() {
      return this.$store.state.addresses;
    },

    wallet() {
      return this.$parent.wallet;
    },

    isSubscription() {
      return this.$parent.isSubscription;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    cscols() {
      return this.isMobile ? 12 : (!this.communities.length || !this.slots.length ? 12 : 6);
    }
  },

  watch: {
    'order.deliveryAddress'(n) {
      this.order.daObject = n ? this.addresses.find(x => x._id == n) : false;
      if (n && this.order.daObject) this.calculateDistance();
      if (!n) this.order.totalDistance = 0;
    },

    'order.slot'(n) {
      if (n) this.order.slotObj = this.slots.find(x => x._id == n);
      else this.order.slotObj = false;
    },

    'order.community'(n) {
      if (n) this.order.communityObj = this.communities.find(x => x._id == n);
      else this.order.communityObj = false;
    },

    'order.deliveryMode'(n) {
      if (n) this.order.dmObj = this.enums.deliveryModes.find(x => x._id == n);
      else this.order.dmObj = false;
    },
    
    'slots'() {
      if (this.order.slot) {
        const exists = this.slots.find(x => x._id == this.order.slot);
        if (!exists) this.order.slot = '';
      }
    },

    'order.deliveryDate'(n) {
      if (n) {
        this.$refs.dialog.save(this.order.deliveryDate);
        this.deliveryDateModal = false;
      }
    }
  },

  data() {
    return {
      communitySearch: '',
      searchCommunityFocus: false,

      showCommunities: true,
      showDeliverySlots: true,
      showDeliveryModes: true,

      aloading: false,
      aprocessing: false,

      deliveryDateModal: false,
      spInfo: {},
    }
  }
}