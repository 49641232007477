import Cart from '../../../services/cart';
import Store from '../../../services/store';

import BillingComponent from '../billing/billing.vue';

export default {
  name: 'CartComponent',

  components: {
    BillingComponent
  },

  props: [
    'order',
    'items',
    'errors',
    'completed',
    'membership'
  ],

  methods: {
    getAssetURL(data, sid) {
      return Store.getAssetURL(data, sid);
    },

    initiateClearCart() {
      this.$store.state.cccModal = true;
    },

    getSaving(item) {
      return item.marketPrice - item.price;
    },

    itemTotal(item) {
      return parseFloat((item.qty * item.price).toFixed(2));
    },

    calculateTax(amount, tax) {
      let taxAmount = 0;
      if (amount && tax) taxAmount = parseFloat(((amount * tax) / 100).toFixed(2));
      return taxAmount;
    },
  
    totalItemTax(item) {
      const cartItem = Cart.findProduct(item);
      const amount = cartItem.beforeTaxPrice * cartItem.qty;
      return this.calculateTax(amount, cartItem.gst);
    },
  
    beforeTaxTotal(item) {
      const cartItem = Cart.findProduct(item);
      return cartItem.beforeTaxPrice * cartItem.qty;
    },
    
    validatePincode() {
      this.order.pincodeVerified = this.invalidPincode = this.errors.pincode = this.order.pincodeObj = false;
      if (this.$refs.pform && !this.$refs.pform.validate()) return;
      if (!this.locations || !this.locations.length) return;

      const pobj = this.locations.find(x => x.pincode === this.order.pincode);
      if (!pobj) {
        this.invalidPincode = true;
        this.errors.pincode = true;
      } else {
        this.order.pincodeObj = pobj;
        this.order.pincodeVerified = true;
      }
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    logo() {
      return Store.logoURL();
    },

    locations() {
      return this.$parent.locations;
    },

    wallet() {
      return this.$parent.wallet;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      invalidPincode: false,

      servicablePin: false,
      showNotes: false,

      validationRules: {
        pincodeRule: [
          v => v && v.length === 6 || 'Enter a valid 6 digit pincode'
        ],
      }
    }
  }
}