import $ from 'jquery';

import Util from '../../services/util';
import Store from '../../services/store';

import ProductItem from '../../components/product-item/product-item.vue';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.vue';
import NotFound from '../../components/not-found/not-found.vue';

export default {
  name: 'Collection',

  created() {
    $('html, body').animate({ scrollTop: 0 }, 0);
    this.breadcrumb = JSON.parse(JSON.stringify(this.$route.meta.breadcrumb));
    if (!this.store._id) return;

    this.slug = this.$route.params.slug;
    if (this.titleText) this.pushBreadcrumb();

    if (this.isTNC) this.fetchCollection();
    else this.fetchGroup();

    this.fetchBrands();
    this.fetchCommonEnums();
    this.loadMore();
  },

  destroyed() {
    Util.setTitle();
  },

  components: {
    ProductItem,
    Breadcrumb,
    NotFound 
  },

  methods: {
    pushBreadcrumb(text = this.titleText) {
      const item = { text: text, disabled: true, href: '' };
      this.breadcrumb.push(item);
    },

    fetchBrands() {
      this.bloading = true;
      return Store
        .getBrands(this.store._id)
        .then(x => {
          this.brands = x.data;
          this.bloading = false;
          this.brands.sort((a, b) => a.name > b.name);
        })
        .catch(e => {
          console.log(e);
          this.bloading = false;
        });
    },

    fetchCommonEnums() {
      this.celoading = true;
      return Store
        .getCommonEnums()
        .then(x => {
          this.enums = x.data;
          this.celoading = false;
          if (this.enums) {
            this.pricing =  this.enums.pricingFilter;
            this.sortOptions = this.enums.sortFilter;
          }
        })
        .catch(e => {
          console.log(e);
          this.celoading = false;
        });
    },

    loadMore() {
      window.onscroll = () => {
        const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight + 300) >= document.documentElement.offsetHeight;
        
        setTimeout(() => {
          if (bottomOfWindow && !this.ploading && !this.loading) {
            if (this.products.length == this.itemsObject.totalItems) return;
            const page = this.itemsObject.pageNumber + 1;
            this.fetchCollection(true, true, page);
          }
        });
      }
    },

    fetchCollection(loading = true, infinite = false, page = this.itemsObject.pageNumber, pageSize = this.itemsObject.itemsPerPage) {
      this.loading = loading;
      if (!loading) this.ploading = true;

      const filter = {};
      const sg = this.selectedGroups;
      const sb = this.selectedBrands;
      const sp = this.selectedPricing;
      const sc = this.selectedCategories;
      if (sg && sg.length) filter.groups = sg;
      if (sb && sb.length) filter.brands = sb;
      if (sp && sp.length) filter.pricing = sp;
      if (sc && sc.length) filter.categories = sc;
      const filterObject = { filters: filter, sortBy: this.sortBy };

      let slug = this.slug;
      if (!this.isTNC) slug = 'group';

      return Store
        .getCollection(this.store._id, slug, filterObject, page, pageSize)
        .then(x => {
          this.itemsObject = x.data;

          if (!infinite) this.products = this.itemsObject.items;
          else this.itemsObject.items.forEach(i => this.products.push(i));

          this.loading = this.ploading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = this.ploading = false;
        });
    },

    fetchGroup() {
      this.loading = true;
      return Store
        .getGroup(this.store._id, this.slug)
        .then(x => {
          this.group = x.data;

          if (this.group) {
            const gof = this.group.of;

            if (gof == 'products') {
              
              this.groups.push(this.group);
              this.selectedGroups.push(this.group._id);
              this.fetchCollection();
              this.fetchGroups();

            } else if (gof == 'categories') {
              this.fetchGroupCategories();
            } else {
              this.loading = false;
            }
           
            this.pushBreadcrumb(this.group.title);
            document.title = this.group.title;
          } else {
            this.loading = false;
          }
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchGroups() {
      this.gloading = true;
      return Store
        .getCollectionGroups(this.store._id, this.group.of)
        .then(x => {
          const groups = x.data;
          this.processGroups(groups);
          this.gloading = false;
        })
        .catch(e => {
          console.log(e);
          this.gloading = false;
        });
    },

    fetchGroupCategories() {
      this.cloading = true;
      return Store
        .getGroupCategories(this.store._id, this.group._id)
        .then(x => {
          this.categories = x.data;
          this.cloading = false;

          const cats = this.categories;
          if (cats && cats.length) {
            cats.forEach(x => this.selectedCategories.push(x._id));
          }
        })
        .catch(e => {
          console.log(e);
          this.gloading = false;
        });
    },

    processGroups(groups) {
      if (groups) {
        groups.forEach(x => { 
          if (!this.selectedGroups.includes(x._id)) this.groups.push(x); 
        });
      }
    },

    currencyPipe(amount) {
      return this.$options.filters.fc(amount);
    },

    getPricingText(item) {
      return this.currencyPipe(item.gte)+(item.lte ? ` to ${this.currencyPipe(item.lte)}` : ' and above');
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    titleText() {
      if (!this.slug) return '';
      if (this.slug == 'trending') return 'Trending';
      if (this.slug == 'new-arrivals') return 'New Arrivals';
      if (this.slug == 'coming-soon') return 'Coming Soon';
      return this.groupTitle;
    },

    groupTitle() {
      if (!this.group) return '';
      return this.group.title;
    },

    productGridUI() {
      return this.$store.state.storeInfo.webstore?.productGridUI || 2;
    },

    isTNC() {
      return ['trending', 'new-arrivals', 'coming-soon'].includes(this.slug);
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  watch: {
    selectedGroups() {
      this.itemsObject.pageNumber = 1;
      this.fetchCollection(false);
    },

    selectedBrands() {
      this.itemsObject.pageNumber = 1;
      this.fetchCollection(false);
    },

    selectedCategories() {
      this.itemsObject.pageNumber = 1;
      this.fetchCollection(false);
    },

    selectedPricing() {
      this.itemsObject.pageNumber = 1;
      this.fetchCollection(false);
    },

    sortBy() {
      this.itemsObject.pageNumber = 1;
      this.fetchCollection(false);
    }
  },

  data() {
    return {
      slug: '',
      breadcrumb: [],

      loading: false,
      gloading: false,
      ploading: false,
      cloading: false,
      group: {},
      enums: {},

      hideFilters: true,
      products: [],

      brands: [],
      pricing: [],
      groups: [],
      categories: [],

      selectedBrands: [],
      selectedPricing: [],
      selectedGroups: [],
      selectedCategories: [],

      sortBy: 0,
      sortOptions: [],

      itemsObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 12
      },
    }
  }
}