<template>
  <section>
    <v-row>
      <v-col cycle v-for="(deal, i) in deals" :key="i" sm="6" cols="12" @click="redirectTo(deal)">
        <v-img v-if="deal.file" :src="getAssetURL(deal.file)" class="rounded-lg align-center link">
          <template v-slot:placeholder><ImageLoader></ImageLoader></template>
        </v-img>
      </v-col>
    </v-row>
    <div v-if="ismb" class="mb-8"></div>
  </section>
</template>

<script>
import Store from '../../services/store';

export default {
  name: 'Deals',

  props: [
    'ismb',
    'deals',
  ],

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    getAssetURL(data) {
      return Store.getAssetURL(data);
    },

    redirectTo(deal) {
      this.$router.push({ name: 'deal', params: { slug: deal.slug || deal._id } });
    }
  }
}
</script>