<template>
  <div class="cbreadcrumb">
    <span v-for="(item, index) in items" :key="index">
      <span :class="getClass(item, index)" @click="goto(item)">{{ item.text }}</span>
      <span v-if="items[index+1]" class="bdivider">/</span>
    </span>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',

  props: [
    'items'
  ],

  methods: {
    getClass(item, index) {
      if ((index + 1) == this.items.length || item.disabled) return 'bdisabled';
      return 'bactive';
    },

    goto(item) {
      if (item.disabled) return;
      this.$router.push({ path: item.href });
    }
  }
}
</script>

<style>
.bactive {
  color: var(--primary);
  cursor:pointer;
}

.cbreadcrumb {
  padding: 18px 0px;
  font-size: 14px;
}

.bdivider { 
  padding: 0 12px;
  color: rgba(0, 0, 0, 0.38);
}

.bdisabled {
  color: rgba(0, 0, 0, 0.38);
}
</style>
