export default {
  
  computed: {
    year() {
      const d = new Date();
      return d.getFullYear();
    },

    store() {
      return this.$store.state.storeInfo;
    },

    pp() {
      return this.$store.state.storeInfo.privacyPolicyURL;
    },

    tc() {
      return this.$store.state.storeInfo.termsConditionsURL;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  methods: {
    navigateAccount(slug) {
      this.$router.push({ name: 'account-slug', params: { slug: slug } });
    },

    navigateURL(url) {
      window.open(url, '_system');
    },

    navigate(url) {
      this.$router.push({ name: url });
    },
  },

  props: [
    'cartFooter'
  ],

  data() {
    return {

    }
  }
}