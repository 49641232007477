<template>
  <section @click="copy">
    <v-tooltip v-if="text.length > length" bottom :max-width="maxWidth">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" v-html="text.length > length ? text.substring(0, length-3) + '...' : text">
        </span>
      </template>
      <span style="white-space: pre-wrap;" v-html="tooltip"></span>
    </v-tooltip>
    <span v-else style="white-space: pre-wrap;" v-html="tooltip"></span>
  </section>
</template>

<script>
export default {
  name: 'TextWrapper',

  props: [
    'text',
    'length',
    'copyDisabled',
    'width'
  ],

  computed: {
    tooltip() {
      return this.text.length > this.length ? (this.copiedText || this.text) : this.text;
    },

    maxWidth() {
      return this.width || 500;
    }
  },

  methods: {
    copy() {
      if (this.copyDisabled) return;
      navigator.clipboard.writeText(this.text);
      this.copiedText = 'Copied';
      setTimeout(() => this.copiedText = false, 1000);
    }
  },

  data() {
    return { 
      copiedText: false
    }
  }
}
</script>