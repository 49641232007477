<template>
  <v-dialog v-model="$store.state.cccModal" max-width="400" persistent>
    <v-card>
      <v-card-title>
        <span>Are you sure?</span>
      </v-card-title>

      <v-card-text>
        Are you sure you want to <b>clear</b> the entire cart?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="error darken-1" text @click="close">No</v-btn>
        <v-btn color="success" text @click="clearCart">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
import Cart from '../../services/cart';
export default {
  methods: {
    close() {
      this.$store.state.cccModal = false;
    },

    clearCart() {
      Cart.clear();
      this.close();
    }
  }
}
</script>