<template>
  <section>
    <section v-if="loading" class="footer-bottom">
      <v-container class="pb-0">
        <Divider></Divider>
        <FooterBottom></FooterBottom>
        <div class="mt-6"></div>
      </v-container>
    </section>

    <section v-else>
      <div class="mt-3"></div>
      <Divider></Divider>
      <FooterBottom></FooterBottom>
      <div class="mt-3"></div>
    </section>
  </section>
</template>
<script>
import FooterBottom from '../../components/footer-bottom/footer-bottom.vue';
export default {
  name: 'CheckoutFooter',
  
  props: [ 'loading' ],

  components: { FooterBottom }
}
</script>

<style>
.footer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  left:0;
}
</style>