import $ from 'jquery';
import moment from 'moment';

import Cart from '../../services/cart';
import User from '../../services/user';
import Order from '../../services/order';
import Store from '../../services/store';

import Address from '../../components/address/address.vue';
import NotFound from '../../components/not-found/not-found.vue';

import CheckoutFooter from './footer.vue';
import CartComponent from './cart/cart.vue';
import BillingComponent from './billing/billing.vue';
import AddressComponent from './address/address.vue';
import PaymentComponent from './payment/payment.vue';
import SubscriptionComponent from './subscription/subscription.vue';

export default {
  name: 'Checkout',

  created() {
    this.scrollTop();
    if (!this.store._id) return;
    
    this.slug = this.$route.params.slug;
    this.tab = 1;
    this.processTabRouting(this.tab);

    //static operations
    if (this.isSubscription) this.processSubscriptionSetup();
    else this.configDeliveryDate();


    this.fetchStoreMiscSettings();
    this.fetchCommonEnums();
    this.fetchCategoryTree();

    if (this.userInfo._id) {
      this.fetchAddresses();
      this.fetchMembership();
      this.fetchLastOrder();
      this.fetchWallet();
      this.uif = true;
    }
  },

  mounted() {
    this.setTitle();
  },

  components: {
    Address,
    NotFound,
    CheckoutFooter,
    CartComponent,
    AddressComponent,
    PaymentComponent,
    BillingComponent,
    SubscriptionComponent
  },

  methods: {
    scrollTop() {
      $('html, body').animate({ scrollTop: 0 }, 0);
    },
    
    setTab() {
      if (this.slug == 'cart') this.tab = 1;
      else if (this.slug == 'address') this.tab = 2;
      else if (this.slug == 'payment') this.tab = 3;
    },

    setTitle() {
      document.title = 'Checkout - '+(this.isSubscription && !this.slug ? 'Subscription' : this.$options.filters.firstLetterUppercase(this.slug));
    },

    gotoRoute(path) {
      this.$router.push({ path: path }).catch(() => {});
    },

    configDeliveryDate() {
      let date = moment().startOf('day');
      if (this.store.isCurrentDayDeliveryDateDisabled) date = date.add(1, 'day');
      this.order.minDeliveryDate = date.toISOString(true);

      let maxDate = date.add('5', 'years');
      this.order.maxDeliveryDate = maxDate.toISOString(true);

      if (this.store.canSelectDeliveryDate) {
        let minDays = 0;
        if (this.store.isCurrentDayDeliveryDateDisabled) minDays = 1;
        if (this.store.leadDays) {
          let date = moment().startOf('day');
          date = date.add((minDays + this.store.leadDays), 'days');
          this.order.minDeliveryDate = date.toISOString(true);
        }

        if (this.store.orderHorizonDays) {
          let date = moment(this.order.minDeliveryDate);
          date = date.add(this.store.orderHorizonDays, 'days');
          this.order.maxDeliveryDate = date.toISOString(true);
        }
      }
    },

    fetchAddresses() {
      this.aloading = true;
      return User.getAddresses(this.store._id, this.userInfo._id)
        .then(r => {
          this.$store.state.addresses = r.data;
          this.aloading = false;
        })
        .catch(e => {
          console.log(e);
          this.aloading = false;
        })
    },

    fetchStoreMiscSettings() {
      this.mloading = true;
      return Store.getStoreMiscSettings(this.store._id)
        .then(res => {
          const x = res.data;
          this.slots = x.slots;
          this.allSlots = x.allSlots;
          this.locations = x.locations;
          this.communities = x.communities;
            
          this.slots = this.slots.filter(x => !x.forSubscriptions);
          if (this.items.some(x => x.isPreorderable)) this.slots = this.slots.filter(y => y.forPreorders);

          this.processPreSettings();
          this.mloading = false;
        })
        .catch(e => {
          console.log(e);
          this.mloading = false;
          this.mainerr = true;
        })
    },

    fetchCommonEnums() {
      this.eloading = true;
      return Store.getCommonEnums()
        .then(res => {
          const x = res.data;
          x.deliveryModes = x.deliveryModes.filter(d => this.store.deliveryModes.includes(d._id))
          x.paymentMethods = x.paymentMethods.filter(d => this.store.paymentMethods.includes(d._id))

          this.order.deliveryMode = x.deliveryModes[0] ? x.deliveryModes[0]._id : 'home-delivery';
          if (x.deliveryModes && x.deliveryModes[0]) this.order.dmObj = x.deliveryModes.find(x => x._id == this.order.deliveryMode);

          this.order.paymentMethod = x.paymentMethods[0] ? x.paymentMethods[0]._id : 'Online';
          if (x.paymentMethods && x.paymentMethods.find(i => i._id === 'BT')) this.fetchBankDetails();

          x.subscriptionCycles = x.subscriptionCycles.filter(d => this.store.subscriptionCycles.includes(d.key));
          x.subscriptionPeriods = x.subscriptionPeriods.filter(d => this.store.subscriptionPeriods.includes(d.key))
        
          //default setting - subscription
          this.subscription.cycle = x.subscriptionCycles[0] ? x.subscriptionCycles[0].key : 'daily';
          this.subscription.period = x.subscriptionPeriods[0] ? x.subscriptionPeriods[0].key : 'morning';
          
          this.resetPaymentMethods();
          this.enums = x;
          this.eloading = false;
        })
        .catch(e => {
          console.log(e);
          this.eloading = false;
          this.mainerr = true;
        })
    },

    resetPaymentMethods() { //as Pay Later should not be available in case of outstanding balance
      if (this.wallet && this.wallet.balance < 0 && this.enums && this.enums.paymentMethods) {
        this.enums.paymentMethods = this.enums.paymentMethods.filter(x => x._id != 'PL');
      }
    },

    fetchCategoryTree() {
      this.cloading = true;
      const ids = this.$store.state.cart.items.map(x => x.category._id);
      return Store
        .getCategoryTree(this.store._id, ids)
        .then(x => {
          if (x && x.data) this.$store.state.cart.categoryTree = x.data;
          this.cloading = false;
        })
        .catch(e => {
          console.log(e);
          this.cloading = false;
        })
    },
  
    fetchBankDetails() {
      this.bloading = true;
      return Store.getBankAccount(this.store._id)
        .then(r => {
          this.bloading = false;
          if (r && r.data) this.bankAccount = r.data;
        })
        .catch(e => {
          console.log(e);
          this.bloading = false;
        });
    },

    fetchMembership() {
      this.mloading = true;
      return User.getMembership(this.store._id, this.userInfo._id)
        .then(r => {
          const x = r.data;
          if (x) {
            this.userMembership = x;
            this.userMembership.canApplyCode = true;
            if (Object.prototype.hasOwnProperty.call(this.userMembership, "canUseCoupons") && !this.userMembership.canUseCoupons) this.userMembership.canApplyCode = false;
            if (x.discountWise === 'order') this.membership = x;
          }
          this.mloading = false;
        })
        .catch(e => {
          console.log(e);
          this.mloading = false;
        })
    },

    fetchWallet() {
      if (this.store.hideWallet) return this.wallet = false;
      this.wloading = false;
      return User
        .getWallet(this.store._id, this.userInfo._id)
        .then(r => {
          const x = r.data;
          if (x) this.wallet = x;
          if (this.wallet.balance === 0) this.order.useWalletAmount = false;
          else {
            if (this.store.maxUseableWalletBalance) {
              this.wallet.totalWalletBalance = this.wallet.balance;
              if (this.store.maxUseableWalletBalanceBy === 'amount') {
                
                if (Cart.getTotal() > this.store.maxUseableWalletBalance) {
                  if (this.wallet.balance > this.store.maxUseableWalletBalance) 
                    this.wallet.balance = this.store.maxUseableWalletBalance;
                }
  
              } else if (this.store.maxUseableWalletBalanceBy === 'percent') {
  
                const toBeUsedWalletBalance = Cart.getTotal() * this.store.maxUseableWalletBalance / 100;
                if (toBeUsedWalletBalance > this.wallet.totalWalletBalance) this.wallet.balance = this.wallet.totalWalletBalance;
                else this.wallet.balance = toBeUsedWalletBalance;
                
              }
              if (this.store.maxUseableWalletBalance === 0) this.wallet.balance = 0;
            }
          }
  
          this.resetPaymentMethods();
          this.wloading = false;
        })
        .catch(e => {
          console.log(e);
          this.wloading = false;
        });
    },

    fetchLastOrder() {
      this.loloading = true;
      return Order  
        .getLastOrder(this.store._id, this.userInfo._id)
        .then(r => {
          const x = r.data;
          if (x && x._id) {
            if (x.address && !x.address.deleted) {
              this.order.daObject = x.address;
              this.order.pincode = x.address.pincode;
              this.order.deliveryAddress = x.address._id;
            }
            //if (x.slot) this.order.slot = x.slot;
            if (x.community) this.order.community = x.community;
            this.processPreSettings();
          }
          this.loloading = false;
        })
        .catch(e => {
          console.log(e);
          this.loloading = false;
        })
    },

    processPreSettings() {
      if (this.order.slot) this.order.slotObj = this.slots.find(x => x._id == this.order.slot);
      if (this.order.community) this.order.communityObj = this.communities.find(x => x._id == this.order.community);
      if (this.order.pincode) {
        const pobj = this.locations.find(x => x.pincode === this.order.pincode);
        if (pobj) {
          this.order.pincodeObj = pobj;
          this.order.pincodeVerified = true;
        }
      }
    },

    calculateSubscriptionDates() {
      const format = 'YYYY-MM-DD';
      this.subscription.minDate = moment().add(1, 'day').format(format);
      this.subscription.maxDate = moment().add(5, 'year').format(format);
      this.subscription.maxEndDate = this.subscription.maxDate;

      if (this.store.subscriptionLeadDays) {
        let date = moment(this.subscription.minDate);
        date = date.add((this.store.subscriptionLeadDays), 'days');
        this.subscription.minDate = date.format(format);
      }

      this.subscription.minEndDate = moment(this.subscription.minDate).add(1, 'days').endOf('day').format(format);
      if (this.store.subscriptionMinimumDays) this.subscription.minEndDate = moment(this.subscription.minDate).add(this.store.subscriptionMinimumDays, 'days').format(format);
      if (this.store.subscriptionMaximumDays) this.subscription.maxEndDate = moment(this.subscription.minEndDate).add(this.store.subscriptionMaximumDays, 'days').format(format);
      this.subscription.endDate = '';
    },

    processSubscriptionSetup() {
      const scart = this.$store.state.subscriptionCart;
      const product = scart.item;

      if (product && product._id) {
        this.calculateSubscriptionDates();
        product.price = Cart.getPrice(product);
        this.subscription.product = product;
        this.subscription.qty = 1;

        this.subscription.startDate = this.subscription.minDate;
        this.subscription.isPrepayment = product.isPrementSubscriptionOnly || false;
      }
    },

    processTabRouting(n) {
      let slug = this.isSubscription ? '' : 'cart';
      if (n == 2 && (this.completed.includes('cart') || this.completed.includes('subscription'))) slug = 'address';
      else if (n == 3 && this.completed.includes('address')) slug = 'payment';

      this.slug = slug;
      this.setTitle();
      this.scrollTop();

      const object = { name: 'checkout'+(this.isSubscription ? '-subscription' : '') };
      if ((this.isSubscription && slug) || !this.isSubscription) object.params = { slug: slug };
      this.$router.push(object).catch(() => {});
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    userInfo() {
      return this.$store.state.userInfo;
    },

    items() {
      return this.$store.state.cart.items.sort((a, b) => a.name > b.name);
    },

    currencyCode() {
      return this.store.userCountry && this.store.userCountry.currencyCode ? this.store.userCountry.currencyCode : 'INR'
    },

    isSubscription() {
      return this.$route.name == 'checkout-subscription' && !!this.$store.state.subscriptionCart.item._id;
    },

    isAddressDisabled() {
      return (!this.isSubscription && (!this.items.length || !this.completed.includes('cart'))) || 
      (this.isSubscription && !this.completed.includes('subscription'))
    }
  },

  watch: {
    tab(n) {
      this.processTabRouting(n);
    },

    slug() {
      this.setTab();
    },

    'items'(n) {
      if (!n || !n.length) this.completed = ['cart'];
    },

    '$route.params'(n) {
      this.slug = n.slug;
    },

    'userInfo._id'(n) {
      if (this.uif || !n) return;
      this.fetchAddresses();
      this.fetchMembership();
      this.fetchLastOrder();
      this.fetchWallet();
    }
  },

  data() {
    return {
      slug: 'cart',
      completed: [],
      loading: false,

      order: {
        slot: '',
        community: '',
        deliveryMode: '',
        paymentMethod: '',
        deliveryDate: '',
        deliveryAddress: '',
        pincodeVerified: false,
        billingAddressSame: true,
        discount: 0,
        cashback: 0,

        communityObj: false,
        pincodeObj: false,
        dmObj: false,
        totalDistance: 0,
        useWalletAmount: true,
        outstandingAmount: 0,
        amountFromWallet: 0,

        minDeliveryDate: '',
        maxDeliveryDate: '',

        taxCode: '',
        dwsProcessed: false
      },
      tab: 1,

      mloading: false,
      eloading: false,
      bloading: false,
      aloading: false,
      cloading: false,

      slots: [],
      allSlots: [],
      locations: [],
      communities: [],

      errors: {
        slot: false,
        pincode: false,
        community: false,
        deliveryDate: false,
        deliveryMode: false,
        deliveryAddress: false,

        maxCOD: false,
        invalidPM: false,
        invalidDA: false,

        dates: false,
        cycle: false,
        period: false
      },

      enums: {},
      wallet: {  balance: 0 },
      
      membership: false,
      userMembership: false,

      bankAccount: false,

      mainerr: false,
      uif: false,


      //.....subscription.....
      subscription: {
        cycle: 'daily',
        period: 'morning',
        startDate: '',
        endDate: '',
        product: '',
        size: '',
        unit: '',
        qty: 0,
        totalDeliveries: 0,
        price: 0,
        deliveryCharge: 0,
        perDeliveryTotal: 0,
        total: 0,
        schedule: [],
        isPostpaid: false,
        discount: 0,
        cashback: 0,

        coupon: '',
        discountOn: '',
        isCashback: false
      },
    }
  }
}