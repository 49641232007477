import $ from "jquery";
import moment from "moment";

import Util from "../../../services/util";
import Cart from "../../../services/cart";
import Order from "../../../services/order";
import Store from "../../../services/store";

import Success from "../success/success.vue";

export default {
  name: "Billing",

  props: [
    "order",
    "slug",
    "items",
    "errors",
    "wallet",
    "completed",
    "membership",
  ],

  components: {
    Success,
  },

  methods: {
    scrollTop() {
      $("html, body").animate({ scrollTop: 0 }, 250);
    },

    nextstep() {
      if (this.slug == "cart") {
        const ls = this.locations;
        if (ls && ls.length && !this.order.pincodeVerified) {
          this.scrollTop();
          this.navigate("cart");
          this.errors.pincode = true;
          setTimeout(() => (this.errors.pincode = false), 2500);
        } else {
          const slug = "address";
          this.$emit("update:slug", slug);
          if (!this.completed.includes("cart")) this.completed.push("cart");
          this.navigate(slug);
        }
      } else if (this.slug == "address") {
        const ss = this.slots;
        const cs = this.communities;

        //community, slot, delivery date, deliveryMode, deliveryAddress validation
        const idsm = this.store.isDeliverySlotSelectionMandatory;
        const icsm = this.store.isCommunitySelectionMandatory;
        const iddm =
          this.store.isDeliveryDateMandatory &&
          this.store.canSelectDeliveryDate;

        if (cs && cs.length && icsm && !this.order.community)
          this.errors.community = true;
        if (ss && ss.length && idsm && !this.order.slot)
          this.errors.slot = true;
        if (iddm && !this.order.deliveryDate) this.errors.deliveryDate = true;
        if (!this.order.deliveryMode) this.errors.deliveryMode = true;
        if (!this.order.deliveryAddress) this.errors.deliveryAddress = true;

        if (
          this.errors.community ||
          this.errors.slot ||
          this.errors.deliveryDate ||
          this.errors.deliveryMode ||
          this.errors.deliveryAddress
        ) {
          this.scrollTop();
          this.navigate("address");
          setTimeout(() => {
            this.errors.slot = false;
            this.errors.community = false;
            this.errors.deliveryDate = false;
            this.errors.deliveryMode = false;
            this.errors.deliveryAddress = false;
          }, 2500);
        } else {
          const slug = "payment";
          this.$emit("update:slug", slug);
          if (!this.completed.includes("address"))
            this.completed.push("address");
          this.navigate(slug);
        }
      } else {
        if (!this.order.paymentMethod) this.errors.invalidPM = true;
        if (
          this.store.distanceWiseDCEnabled &&
          (!this.order.daObject ||
            !this.order.daObject.lat ||
            !this.order.daObject.long)
        )
          this.errors.invalidDA = true;
        if (
          this.store.maxCODAmount &&
          this.order.paymentMethod === "COD" &&
          this.cartTotal > this.store.maxCODAmount
        )
          this.errors.maxCOD = true;

        if (
          this.errors.maxCOD ||
          this.errors.invalidPM ||
          this.errors.invalidDA
        ) {
          if (this.errors.invalidPM) this.scrollTop();
          setTimeout(
            () => {
              this.errors.maxCOD = false;
              this.errors.invalidPM = false;
              this.errors.invalidDA = false;
            },
            this.errors.invalidPM ? 3000 : 5000
          );
        } else {
          this.initProcess();
        }
      }
    },

    initProcess() {
      if (this.order.paymentMethod === "Online" && this.orderTotal) {
        if (this.payment) this.processOnlinePayment();
        else this.fetchPaymentSetting();
      } else {
        if (this.order.paymentMethod === "CPU") this.cpuModal = true;
        else this.placeOrder();
      }
    },

    processCPUOrder() {
      this.cpuModal = false;
      this.placeOrder();
    },

    fetchPaymentSetting() {
      this.ploading = true;
      return Store.getPaymentSettings(this.store._id).then(
        (res) => {
          const x = res.data;
          if (x && x.isActive) {
            this.payment = x;
          }

          this.ploading = false;
          this.processOnlinePayment();
        },
        (e) => {
          console.log(e);
          this.ploading = false;
          this.mainerr = true;
        }
      );
    },

    processOnlinePayment() {
      this.oloading = true;

      const amount = this.razorpayOptions().amount;
      const currencyCode = this.razorpayOptions().currencyCode;
      const options = { amount: amount, currencyCode: currencyCode };

      return Order.createRazorpayOrder(this.store._id, options)
        .then((r) => {
          const res = r.data;
          this.payOnline(res.orderId);
          this.oloading = false;
        })
        .catch(() => {
          console.log("could not generate rzp oid");
          this.oloading = false;
          this.payOnline();
        });
    },

    razorpayOptions() {
      const amount = parseFloat((this.orderTotal * 100).toFixed(2));
      const currencyCode = this.$parent.currencyCode;
      return { amount: amount, currencyCode: currencyCode };
    },

    payOnline(orderId = "") {
      const amount = this.razorpayOptions().amount;
      const currencyCode = this.razorpayOptions().currencyCode;
      const options = Util.getRazorpayOptions(
        currencyCode,
        amount,
        "Order Payment Transaction",
        this.payment && this.payment.key ? this.payment.key : "",
        orderId
      );

      const newthis = this;
      options.handler = function (response) {
        newthis.placeOrder(response.razorpay_payment_id);
      };
      const razorpay = new window.Razorpay(options);
      razorpay.on("payment.failed", function (response) {
        if (response && response.error && response.error.description) {
          newthis.errorCallBack(response.error.description);
        }
      });
      razorpay.open();
    },

    errorCallBack(text) {
      this.$store.state.snackbar.text =
        text || "Something went wrong, try again";
      this.$store.state.snackbar.timeout = 3000;
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    placeOrder(paymentId = "") {
      this.oloading = true;

      const order = {
        type: "website",
        items: this.items,
        paymentId: paymentId,
        address: this.order.deliveryAddress,
        deliveryMode: this.order.deliveryMode,
        paymentMethod: this.order.paymentMethod,
        billingAddressSame: this.order.billingAddressSame,

        notes: this.order.notes,
        taxCode: this.order.taxCode,

        outstandingAmount: this.order.outstandingAmount,
        amountFromWallet: !this.order.useWalletAmount
          ? 0
          : this.order.amountFromWallet,

        cashback: this.order.cashback,
        deliveryCharge: this.deliveryCharge,
        packagingCharge: this.packagingCharge,
        otherCharges: this.otherCharges,
        discount: this.order.discount || this.membershipDiscount,

        taxInAmount: this.totalTax,
        taxableAmount: this.totalPayable(true),
        tax: this.store.applyTax && this.store.tax ? this.store.tax : 0,

        amount: this.totalPayable(),
        currencyCode: this.$parent.currencyCode,
      };

      if (this.order.slot) order.slot = this.order.slot;
      if (this.order.coupon) order.coupon = this.order.coupon;
      if (this.order.community) order.community = this.order.community;
      if (this.order.deliveryDate) order.deliveryDate = this.order.deliveryDate;

      //for distance wise delivery charges
      if (this.store.distanceWiseDCEnabled) {
        order["baseDistance"] = this.store.baseKM;
        order["baseDeliveryCharge"] = this.store.baseDeliveryCharge;
        order["deliveryChargePerKM"] = this.store.deliveryChargePerKM;
        order["distance"] = this.order.totalDistance;
      }

      return Order.placeOrder(this.store._id, order)
        .then((x) => {
          Cart.clear();
          this.oloading = false;
          this.completeAbandonedOrder();
          if (x.data) this.$store.state.oid = x.data.oid;
          this.$router.push({ name: "checkout-success" }).catch(() => {});
        })
        .catch(() => {
          this.oloading = false;
          this.throwError();
        });
    },

    throwError() {
      this.errorCallBack("Oops! Something went wrong; try later.");
    },

    completeAbandonedOrder() {
      if (!this.order.aoid) return;

      this.aoprocessing = true;
      return Order.updateAbandonedOrder(this.order.aoid, {
        status: "completed",
      })
        .then(() => {
          this.order.aoid = "";
          this.aoprocessing = false;
        })
        .catch((e) => {
          console.log(e);
          this.aoprocessing = false;
        });
    },

    navigate(to) {
      this.$router
        .push({ name: "checkout", params: { slug: to } })
        .catch(() => {});
    },

    isNotEmpty(value) {
      return value != undefined && value != null;
    },

    processDeliveryChargeRules(dc) {
      if (!this.order.community || !this.order.deliveryDate) return dc;
      const sc = this.order.communityObj;
      if (!sc.dcScheduled) return dc;

      const selected = this.order.deliveryDate;
      const selectedDay = moment(selected).format("dddd");
      const selectedDate = moment(selected).format("YYYY-MM-DD");

      const daySchedules = sc.schedule.filter(
        (x) => x.day == selectedDay && x.mode == "for"
      );
      if (!daySchedules || !daySchedules.length) return dc;

      const orderTime = moment().format("YYYY-MM-DD HH:mm");
      daySchedules.forEach((ds) => {
        const dateTime = moment(
          selectedDate + " " + ds.endsAt,
          "YYYY-MM-DD HH:mm"
        );
        const ruleTime = dateTime.format("YYYY-MM-DD HH:mm");
        if (this.isSameOrBefore(orderTime, ruleTime)) dc = ds.deliveryCharge;
      });

      return dc;
    },

    isTimeBetween(startTime, endTime, serverTime) {
      let start = moment(startTime, "H:mm");
      let end = moment(endTime, "H:mm");
      let server = moment(serverTime, "H:mm");
      if (end < start) {
        return (
          (server >= start && server <= moment("23:59:59", "h:mm:ss")) ||
          (server >= moment("0:00:00", "h:mm:ss") && server < end)
        );
      }
      return server >= start && server < end;
    },

    isSameOrBefore(time1, time2) {
      return moment(time1).isSameOrBefore(time2);
    },

    getAmount(type) {
      const order = this.order;
      if (
        type == "deliveryCharge" && //delivery free if
        (order.isDeliveryFree ||
          order.deliveryMode == "pick-up" ||
          (this.deliveryFreeAbove && this.cartTotal >= this.deliveryFreeAbove))
      )
        return 0;

      let minimumOrder = 0;
      let deliveryCharge = 0;
      let deliveryFreeAbove = 0;

      //apply standard delivery charge
      minimumOrder = this.store.minimumOrder;
      deliveryCharge = this.store.deliveryCharge;
      deliveryFreeAbove = this.store.deliveryFreeAbove;

      //apply pincode wise delivery charge
      const po = order.pincodeObj;
      if (po && order.pincodeVerified) {
        if (this.isNotEmpty(po.minimumOrder) && po.minimumOrder >= 0)
          minimumOrder = po.minimumOrder;
        if (this.isNotEmpty(po.deliveryCharge) && po.deliveryCharge >= 0)
          deliveryCharge = po.deliveryCharge;
        if (this.isNotEmpty(po.deliveryFreeAbove) && po.deliveryFreeAbove >= 0)
          deliveryFreeAbove = po.deliveryFreeAbove;
      }

      //apply community wise delivery charge
      const co = order.communityObj;
      if (co && order.community) {
        if (this.isNotEmpty(co.minimumOrder) && co.minimumOrder >= 0)
          minimumOrder = co.minimumOrder;
        if (this.isNotEmpty(co.deliveryCharge) && co.deliveryCharge >= 0)
          deliveryCharge = co.deliveryCharge;
        if (this.isNotEmpty(co.deliveryFreeAbove) && co.deliveryFreeAbove >= 0)
          deliveryFreeAbove = co.deliveryFreeAbove;

        deliveryCharge = this.processDeliveryChargeRules(deliveryCharge); //to apply dynamic charge
      }

      if (!this.store.distanceWiseDCEnabled) {
        const cwEnabled =
          this.store.categoryWiseDeliveryCharge ||
          this.store.mainCategoryWiseDeliveryCharge;

        deliveryCharge =
          deliveryCharge +
          (this.store.categoryWiseDeliveryCharge
            ? this.store.mainCategoryWiseDeliveryCharge
              ? Cart.getMainCategoryWiseDC()
              : Cart.getCategoryWiseDC()
            : 0); //any additional category wise delivery charge

        if (Cart.getIsPercent() && !cwEnabled) {
          const perDC = (this.cartTotal * deliveryCharge) / 100;
          deliveryCharge = parseFloat(perDC.toFixed(2));
        }
      } else {
        //calculate distance wise delivery charges
        const totalDistance = order.totalDistance;
        if (totalDistance > this.store.baseKM) {
          let distance = totalDistance;
          distance = distance - this.store.baseKM;
          deliveryCharge =
            this.store.baseDeliveryCharge +
            distance * this.store.deliveryChargePerKM;
        } else {
          deliveryCharge = this.store.baseDeliveryCharge;
        }
      }

      if (type == "minimumOrder") return minimumOrder;
      if (type == "deliveryCharge") return deliveryCharge;
      if (type == "deliveryFreeAbove") return deliveryFreeAbove;
    },

    totalPayable(taxable = false) {
      let totalPayable =
        this.cartTotal +
        this.packagingCharge +
        this.otherCharges +
        this.deliveryCharge +
        this.walletAmount -
        this.order.discount;
      if (totalPayable && totalPayable >= this.membershipDiscount)
        totalPayable = totalPayable - this.membershipDiscount;
      if (!taxable && this.store.applyTax)
        totalPayable = totalPayable + this.totalTax;

      return parseFloat(totalPayable.toFixed(2));
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    cartTotal() {
      return Cart.getTotal();
    },

    locations() {
      return this.$parent.locations;
    },

    communities() {
      return this.$parent.communities;
    },

    slots() {
      return this.$parent.slots;
    },

    membershipDiscount() {
      let discount = 0;
      const membership = this.membership;
      if (membership && membership.discount > 0) {
        const ct = this.cartTotal;
        if (membership.discountBy === "percent")
          discount = (ct * membership.discount) / 100;
        else if (ct >= membership.discount) discount = membership.discount;
        if (discount) discount = parseFloat(discount.toFixed(2));
      }

      return discount;
    },

    minimumOrder() {
      return this.getAmount("minimumOrder");
    },

    deliveryCharge() {
      return this.getAmount("deliveryCharge");
    },

    deliveryFreeAbove() {
      return this.getAmount("deliveryFreeAbove");
    },

    packagingCharge() {
      return this.store.packagingCharge || 0;
    },

    otherCharges() {
      return this.store.otherCharges || 0;
    },

    walletAmount() {
      if (this.wallet && this.wallet.balance < 0) {
        let balance = this.wallet.balance + "";
        balance = balance.replace(/-/g, "");
        const outstandingAmount = parseFloat(balance);
        this.order.outstandingAmount = outstandingAmount;
        return outstandingAmount;
      }
      return 0;
    },

    walletAmountWithoutMinus() {
      let displayableAmount = 0;
      if (!this.wallet || !this.order.useWalletAmount) {
        this.order.amountFromWallet = 0;
        this.order.outstandingAmount = 0;
        return displayableAmount;
      }

      const totalPayable = this.totalPayable();
      if (this.wallet.balance === 0) displayableAmount = 0;
      else if (this.wallet.balance < 0) {
        let balance = this.wallet.balance + "";
        balance = balance.replace(/-/g, "");
        displayableAmount = parseFloat(balance);
      } else if (totalPayable >= this.wallet.balance) {
        displayableAmount = this.wallet.balance;
        this.order.amountFromWallet = displayableAmount;
      } else {
        this.order.amountFromWallet = totalPayable;
        displayableAmount = totalPayable;
      }

      return displayableAmount;
    },

    totalTax() {
      let tax = 0;
      if (
        this.store.applyTax &&
        this.store.taxType === "order" &&
        this.store.tax
      ) {
        const taxableAmount = this.totalPayable(true);
        tax = parseFloat(((taxableAmount * this.store.tax) / 100).toFixed(2));
      }
      return tax;
    },

    orderTotal() {
      let totalPayable = this.totalPayable();
      if (
        this.wallet &&
        this.wallet.balance > 0 &&
        this.order.useWalletAmount
      ) {
        if (totalPayable >= this.wallet.balance)
          totalPayable = totalPayable - this.wallet.balance;
        else totalPayable = 0;
      }

      return parseFloat(totalPayable.toFixed(2));
    },

    canPlace() {
      return this.slug !== "cart" ? this.cartTotal >= this.minimumOrder : true;
    },

    wan() {
      return this.store.whatsappNumber;
    },
  },

  data() {
    return {
      oloading: false,
      cpuModal: false,
      showWallet: true,
      aoprocessing: false,

      ploading: false,
      payment: false,
    };
  },
};
