import $ from 'jquery';
import Store from '../../services/store';

import SectionHeader from '../../components/section-header/section-header.vue';
import ProductItem from '../../components/product-item/product-item.vue';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.vue';
import NotFound from '../../components/not-found/not-found.vue';

export default {

  created() {
    $('html, body').animate({ scrollTop: 0 }, 0);
    this.breadcrumb = JSON.parse(JSON.stringify(this.$route.meta.breadcrumb));
    if (!this.store._id) return;

    this.fetchCategories();
    this.loadMore();
  },

  components: {
    SectionHeader,
    ProductItem,
    Breadcrumb,
    NotFound
  },

  methods: {
    fetchCategories() {
      this.loading = true;
      return Store
        .getCategoriesTree(this.store._id)
        .then(x => {
          this.categories = x.data;
          this.loading = false;

          const cs = this.categories;
          if (cs && cs.length) {
            this.category = cs[0];
            this.fetchProducts();
          }
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchProducts(all = !this.category.parent, infinite = false, page = this.productsObject.pageNumber, pageSize = this.productsObject.itemsPerPage) {
      this.ploading = true;

      let Request;
      if (!all) Request = Store.getProductsByCategory(this.store._id, this.category._id, {}, page, pageSize, this.search)
      else Request = Store.getAllProductsByMainCategory(this.store._id, this.category._id, {}, page, pageSize, this.search);

      return Request
        .then(x => {
          this.productsObject = x.data;

          if (!infinite) this.products = this.productsObject.items;
          else this.productsObject.items.forEach(i => this.products.push(i));

          this.ploading = false;
        })
        .catch(e => {
          console.log(e);
          this.ploading = false;
        });
    },

    loadMore() {
      window.onscroll = () => {
        const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight + 300) >= document.documentElement.offsetHeight;

        setTimeout(() => {
          if (bottomOfWindow && !this.ploading && !this.loading) {
            if (this.products.length == this.productsObject.totalItems) return;
            const page = this.productsObject.pageNumber + 1;
            this.fetchProducts(!this.category.parent, true, page);
          }
        });
      }
    },

    selectCategory(category) {
      this.category = category;
      this.fetchProducts(!this.category.parent, false, 1);
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    productGridUI() {
      return this.$store.state.storeInfo.webstore?.productGridUI || 2;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  
  data() {
    return {
      breadcrumb: [],

      loading: false,
      ploading: false,
      categories: [],
      category: {},

      search: '',
      products: [],
      productsObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 12
      },
    }
  }
}