import $ from 'jquery';

import AddressList from '../../../components/address-list/address-list.vue';

export default {
  name: 'Addresses',

  created() {
    this.scrollTop();
    if (!this.user._id) return this.$router.push({ name: 'account' }).catch(() => {});
  },

  components: {
    AddressList
  },

  methods: {
    scrollTop() {
      $('html, body').animate({ scrollTop: 0 }, 250);
    },

    addAddress() {
      this.$store.state.editedAddressItem = {};
      this.$store.state.addressModal = true;
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    }
  },

  data() {
    return {
      aloading: false,
    }
  }
}