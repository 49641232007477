import User from "../../services/user";
import Store from "../../services/store";

import GoogleMap from "../google-map/google-map.vue";

export default {
  components: {
    GoogleMap,
  },

  props: ["editedItem", "addressModified"],

  methods: {
    close() {
      this.$store.state.addressModal = false;
    },

    saveAddress() {
      if (this.$refs.aform && !this.$refs.aform.validate()) return;
      this.processing = true;

      let Request;
      if (this.editedItem._id)
        Request = User.editAddress(this.editedItem._id, this.editedItem);
      else
        Request = User.addAddress(
          this.store._id,
          this.user._id,
          this.editedItem
        );

      return Request.then((x) => {
        this.close();
        this.processing = false;
        this.$emit("update:addressModified", {
          isNew: !this.editedItem._id,
          _id: x.data._id,
        });
      }).catch((e) => {
        console.log(e);
        this.processing = true;
      });
    },

    fetchCityByPincode(pincode) {
      if (!pincode) return;
      this.ploading = true;
      return Store.getCityByPincode(pincode)
        .then((x) => {
          if (x && x.data) {
            const pi = x.data;
            const pis = pi.state;
            this.editedItem.city = pi.name;
            if (pis && pis.name) this.editedItem.state = pis.name;
            if (pis && pis.country && pis.country.name)
              this.editedItem.country = pis.country.name;
          }
          this.ploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.ploading = false;
        });
    },

    mapNext() {
      this.step = 2;
    },
  },

  watch: {
    "$store.state.addressModal"(n) {
      if (n) {
        if (!this.editedItem.callingCode) this.editedItem.callingCode = "91";
        if (!this.editedItem.country) this.editedItem.country = "India";
        if (!this.editedItem.name) this.editedItem.name = this.user.name;
        if (!this.editedItem.mobile) this.editedItem.mobile = this.user.mobile;
        if (!this.hasMapAccess) this.step = 2;
        if (this.editedItem._id) this.step = 2;
      }
    },

    "editedItem.pincode"(n) {
      const pincode = n ? n.trim() : "";
      if (pincode && pincode.length != 6) return;
      this.fetchCityByPincode(pincode);
    },

    maperror(n) {
      if (!n) this.step = 2;
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    hasMapAccess() {
      return this.store.googleAPIKeyWeb || this.store.googleAPIKey;
    },
  },

  data() {
    return {
      step: 1,
      ploading: false,
      maploading: false,
      processing: false,

      maperror: false,

      address: {},
      countries: [],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
      },
    };
  },
};
