<template>
  <v-container>
    <div class="mt-10"></div>
    <v-row>
      <v-col>
        <h2 class="mt-10">Privacy Policy</h2>
        <div class="mb-10"></div>

        <div v-if="loading">
          <v-progress-linear indeterminate height="3px"></v-progress-linear>
        </div>

        <div v-else-if="policy" v-html="policy.description"></div>

        <section v-else>
          <p>
            {{ store.appName }} is a product of {{ store.name }}.
          </p>
          <p>
            This page is used to inform visitors regarding
            my policies with the collection, use, and
            disclosure of Personal Information if anyone decided to use
            my Service.
          </p>
          <p>
            If you choose to use my Service, then you agree
            to the collection and use of information in relation to this
            policy. The Personal Information that I collect is
            used for providing and improving the Service.
            I will not use or share your
            information with anyone except as described in this Privacy
            Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings
            as in our Terms and Conditions, which is accessible at
            {{ store.appName }} unless otherwise defined in this Privacy
            Policy.
          </p>
          <p><strong>Information Collection and Use</strong></p>
          <p>
            For a better experience, while using our Service,
            I may require you to provide us with certain
            personally identifiable information, including but not limited to Name, email, mobile number, picture. The
            information that I request will be
            retained on your device and is not collected by me in any way.
          </p>
          <p>
            The app does use third party services that may collect
            information used to identify you.
          </p>
          <div>
            <p>
                Link to privacy policy of third party service providers
                used by the app
            </p>
            <ul>
                <li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li>
            </ul>
          </div>
          <p class="mt-4"><strong>Log Data</strong></p>
          <p>
            I want to inform you that whenever
            you use my Service, in a case of an error in the
            app I collect data and information (through third
            party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet
            Protocol (“IP”) address, device name, operating system
            version, the configuration of the app when utilizing
            my Service, the time and date of your use of the
            Service, and other statistics.
          </p>
          <p><strong>Cookies</strong></p>
          <p>
            Cookies are files with a small amount of data that are
            commonly used as anonymous unique identifiers. These are
            sent to your browser from the websites that you visit and
            are stored on your device's internal memory.
          </p>
          <p>
            This Service does not use these “cookies” explicitly.
            However, the app may use third party code and libraries that
            use “cookies” to collect information and improve their
            services. You have the option to either accept or refuse
            these cookies and know when a cookie is being sent to your
            device. If you choose to refuse our cookies, you may not be
            able to use some portions of this Service.
          </p>
          <p><strong>Service Providers</strong></p>
          <p>
            I may employ third-party companies
            and individuals due to the following reasons:
          </p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>
            I want to inform users of this
            Service that these third parties have access to your
            Personal Information. The reason is to perform the tasks
            assigned to them on our behalf. However, they are obligated
            not to disclose or use the information for any other
            purpose.
          </p>
          <p><strong>Security</strong></p>
          <p>
            I value your trust in providing us
            your Personal Information, thus we are striving to use
            commercially acceptable means of protecting it. But remember
            that no method of transmission over the internet, or method
            of electronic storage is 100% secure and reliable, and
            I cannot guarantee its absolute security.
          </p>
          <p><strong>Links to Other Sites</strong></p>
          <p>
            This Service may contain links to other sites. If you click
            on a third-party link, you will be directed to that site.
            Note that these external sites are not operated by
            me. Therefore, I strongly advise you to
            review the Privacy Policy of these websites.
            I have no control over and assume no
            responsibility for the content, privacy policies, or
            practices of any third-party sites or services.
          </p>
          <p><strong>Children’s Privacy</strong></p>
          <p>
            These Services do not address anyone under the age of 13.
            I do not knowingly collect personally
            identifiable information from children under 13. In the case
            I discover that a child under 13 has provided
            me with personal information,
            I immediately delete this from our servers. If you
            are a parent or guardian and you are aware that your child
            has provided us with personal information, please contact
            me so that I will be able to do
            necessary actions.
          </p>
          <p><strong>Changes to This Privacy Policy</strong></p>
          <p>
            I may update our Privacy Policy from
            time to time. Thus, you are advised to review this page
            periodically for any changes. I will
            notify you of any changes by posting the new Privacy Policy
            on this page. These changes are effective immediately after
            they are posted on this page.
          </p>
          <div v-if="store.contactNumber">
            <p><strong>Contact Us</strong></p>
            <p>
              If you have any questions or suggestions about
              my Privacy Policy, do not hesitate to contact
              us at [{{ store.contactNumber }}].
            </p>
          </div>

        </section>

        <div class="mb-15"></div>
      </v-col>
    </v-row>
    <div class="mt-10"></div>
  </v-container>
</template>
<script>
import $ from 'jquery';
import Util from '../../services/util';
import Store from '../../services/store';

export default {
  name: 'PrivacyPolicy',

  created() {
    $('html, body').animate({ scrollTop: 0 }, 250);
    document.title = 'Privacy Policy';
    this.fetchPolicy();
  },

  methods: {
    fetchPolicy() {
      this.loading = true;
      return Store
        .getPolicy(this.store._id, 'privacy-policy')  
        .then(x => {
          this.policy = x.data;
          
          if (this.policy && this.policy.length) this.policy = this.policy[0];
          else this.policy = false;

          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    }
  },

  destroyed() {
    Util.setTitle();
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    }
  },

  watch: {
    $route(n) {
      console.log(n);
    }
  },

  data() {
    return {
      loading: false,
      policy: '',
    }
  }
}
</script>