import _ from 'underscore';
import Store from '../../services/store';

export default {
  name: 'GoogleMap',

  created() {
    this.oolocation = JSON.parse(JSON.stringify(this.location));
    this.initMap();
  },

  props: [
    'maperror',
    'location',
    'next'
  ],

  methods: {
    initMap() {
      this.loading = true;
      this.mloading = true;
      this.detectLocation();
    },

    detectLocation() {
      navigator.geolocation.getCurrentPosition(this.success, this.error, [this.options]);
    },

    error(error) {
      this.err = true;
      this.loading = false;
      this.$emit('update:maperror', true);

      //show dummy coords map
      const cl = this.oolocation;
      if (cl && cl.lat && cl.long) {
        this.dummyCoords.latitude = cl.lat;
        this.dummyCoords.longitude = cl.long;
      }
      this.success({ coords: this.dummyCoords });
      console.warn(error && error.code ? 'error-code: '+ error.code : error);
    },

    success(resp) {
      const info = resp.coords;
      this.lat = info.latitude;
      this.long = info.longitude;
      this.clInfo = { lat: this.lat, long: this.long };

      const cl = this.oolocation;
      if (cl && cl.lat && cl.long) {
        this.lat = cl.lat;
        this.long = cl.long;
      }

      const latLng = new window.google.maps.LatLng(this.lat, this.long);
      this.setupMap(latLng, true);
      this.mloading = false;
    },

    setupMap(latLng, setMarker = false) {
      const mapOptions = {
        zoom: 15,
        center: latLng,
        zoomControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        fullscreenControl: false,
        mapId: 'ae694e4f970a86ce',
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      };

      const element = this.$refs['google'];
      if (!element) return;

      this.map = new window.google.maps.Map(element, mapOptions);
      window.google.maps.event.addListener(this.map, 'click', (event) => {
        const latLng = event.latLng;
        this.addMarker(latLng.lat(), latLng.lng());
      });

      if (setMarker) setTimeout(() => this.addMarker(latLng.lat(), latLng.lng(), 1), 500);
    },

    addMarker(lat, lng, newMarker = 0) {
      let marker;
      let latLng = new window.google.maps.LatLng(lat, lng);
      if (this.marker && !newMarker) {
        this.marker.setPosition(latLng);
        marker = this.marker;
      } else {
        marker = new window.google.maps.Marker({
          map: this.map,
          animation: window.google.maps.Animation.DROP,
          position: latLng,
          draggable: true
        });
      }
      
      window.google.maps.event.addListener(marker, 'dragend', () => {
        this.detectAddress(latLng, true);
      });

      this.detectAddress(latLng);
      this.marker = marker;
    },

    detectAddress(position) {
      const geocoder = new window.window.google.maps.Geocoder();
      geocoder.geocode({'latLng': position}, (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            if (results && results.length > 0) {
              const componentObject = results.find(x => x.types && x.types.includes('street_address')) || results[0];
              const components = componentObject.address_components;

              const address = componentObject.formatted_address;
              const geometry = componentObject.geometry;
              const array = address.split(',');
              array.pop();
              array.pop();

              if (array.length > 3) array.length = 3;
              this.location.street = array.join(', ');

              let newArea = ''
              components.forEach(x => {
                if (x.types[0] === 'country') this.location.country = x.long_name;
                else if (x.types[0] === 'administrative_area_level_1') this.location.state = x.long_name;
                else if (x.types[0] === 'administrative_area_level_2') this.location.city = x.long_name; 
                else if (x.types[0] === 'postal_code') this.location.pincode = x.long_name; 
                
                if (x.types.includes('sublocality_level_1')) newArea = x.long_name;
                if (!newArea && x.types.includes('sublocality')) newArea = x.long_name;
                if (!newArea && x.types.includes('neighborhood')) newArea = x.long_name;
                if (!newArea && x.types.includes('locality')) newArea = x.long_name;
                
                this.location.area = newArea;
              });

              this.location.lat = geometry.location.lat();
              this.location.long = geometry.location.lng();
              this.gloc = Object.assign({}, this.location);
              this.loading = false;
            } else {
              console.log('Cannot determine location at this location.');
              this.loading = false;
            }
         }
        }
      })
    },

    setCurrentLocation() {
      const cl = this.clInfo;
      if (this.marker && this.marker.position.lat() == cl && this.marker.position.lng() == cl.long) return;

      if (cl.lat && cl.long) {
        const position = new window.google.maps.LatLng(cl.lat, cl.long); 
        this.setupMap(position, true);
      } else {
        this.detectLocation();
      }
    },

    searchPlaces(keyword) {
      if (!keyword) return this.searching = false;
      this.searching = true;
  
      this.lastInput = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
  
      this.searching = true;

      return Store
        .searchPlaces(this.store._id, keyword)
        .then(x => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;

          this.places = x.data;
          this.lastResponse = thisRequest;
          this.searching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.searching = false;
        })
    },

    placeSelected(item) {
      this.places = [];
      this.input = item.description;

      const newthis = this;
      const service = new window.google.maps.places.PlacesService(this.map);
      service.getDetails({
        placeId: item.placeId,
        fields: ['geometry']
      }, function(result) {
        if (!result || !result.geometry || !result.geometry.location) return;
        const latLng = result.geometry.location;
        newthis.setupMap(latLng, true);
      });
    }
  },

  watch: {
    'search':  _.debounce(function(val) {
      if (val === this.lastInput || !val || val == this.selected.description) return;
      this.searchPlaces(val);
    }, 500),

    selected(n) {
      if (n) this.placeSelected(n);
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      map: '',
      lat: '',
      long: '',
      marker: '',

      places: [],
      clInfo: false,

      gloc: {},
      oolocation: {},

      loading: false,
      mloading: false,
      searching: false,
      search: '',

      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,
      lastInput: '',
      selected: {},

      dummyCoords: {
        latitude: 19.1173805,
        longitude: 72.905699
      },

      options: {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      },

      err: false,
    }
  }
}