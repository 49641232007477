<template>

  <v-row v-if="isSingle">
    <v-col cols="2" sm="1"> 
      <v-skeleton-loader height="80px" class="mb-2" type="image"></v-skeleton-loader>
      <v-skeleton-loader height="80px" class="mb-2" type="image"></v-skeleton-loader>
    </v-col>
    
    <v-col>
      <v-skeleton-loader class="only-img" height="300px" type="image" ></v-skeleton-loader>
    </v-col>

    <v-col cols="6" v-if="!isMobile">
      <v-skeleton-loader class="mx-auto" type="article"></v-skeleton-loader>
      <v-skeleton-loader class="mx-auto mt-2" type="article"></v-skeleton-loader>
    </v-col>
  </v-row>

  <v-row v-else-if="left">
    <v-col>
      <v-skeleton-loader class="mb-2" type="article"></v-skeleton-loader>
      <v-skeleton-loader class="mb-2" type="article"></v-skeleton-loader>
      <v-skeleton-loader class="mb-2" type="article"></v-skeleton-loader>
    </v-col>
  </v-row>

  <v-row v-else-if="preLoading" class="mt-5 mb-5">
    <v-col>
      <v-skeleton-loader class="mb-2 only-img" height="350px" type="image"></v-skeleton-loader>

      <v-row class="mt-5">
        <v-col>
          <v-skeleton-loader class="mb-2 only-img" height="250px" type="image"></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader class="mb-2 only-img" height="250px" type="image"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col>
          <v-skeleton-loader class="mb-3" type="heading"></v-skeleton-loader>
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="mt-15">
        <v-col><SkeletonLoader :items="8"></SkeletonLoader></v-col>
      </v-row>
    </v-col>
  </v-row>

  <v-row v-else>
    <v-col data-aos="zoom-in" class="mt-25" cols="6" :sm="cols || 3" v-for="i in items" :key="i">
      <v-skeleton-loader 
        class="mx-auto br-25" 
        max-width="300" 
        type="image, article"></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<style>
.only-img .v-skeleton-loader__image {
  height: 100%;
}
</style>

<script>
  export default {
    name: 'SkeletonLoader',
    props: [
      'cols',
      'left',
      'items',
      'isSingle',
      'preLoading'
    ],

    computed: {
      isMobile() {
        return this.$store.state.isMobile;
      },
    }
  }
</script>