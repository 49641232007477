<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-progress-circular
          indeterminate
          :size="60"
          :width="5"
          style="margin-top: -200px"
          color="#cecfd5"
          v-if="loading"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Misc from "../../services/misc";

const defaultURL = window.location.origin;

export default {
  name: "Apps",

  created() {
    const route = this.$route;
    const uuid = route.params.uuid;
    const slug = route.params.slug;

    if (!uuid && !slug) return this.redirectBase();
    const input = slug || uuid;
    this.fetchAppsURL(input);
  },

  methods: {
    redirectBase() {
      window.location.replace(defaultURL);
    },

    fetchAppsURL(input) {
      this.loading = true;
      return Misc.getAppURLs(input)
        .then((response) => {
          const data = response.data;

          let url = defaultURL;
          if (data) {
            const os = this.osInfo;
            if (os == "Android") url = data.androidURL;
            else if (os == "iOS") url = data.iosURL;
            else if (data.website) {
              url = data.website;
              // append url_source and slug to website URL for tracking as query params
              const slug = this.$route.params.slug;
              if (slug) {
                url += `?incoming_slug=${slug}&url_source=deeplink-share`;
              }
            }
          }

          window.location = url;
          if (url != defaultURL) {
            setTimeout(() => window.close(), 1000);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.redirectBase();
        });
    },
  },

  computed: {
    osInfo() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
  },

  data() {
    return {
      loading: false,
    };
  },
};
</script>
