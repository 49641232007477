import Vue from "vue";
import Vuex from "vuex";

import router from "./router";
import Cart from "./services/cart";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: false,

  state: {
    authenticated: false,
    token: localStorage.getItem("token") || "",
    userInfo: {},
    addresses: [],
    uuid: "",

    showBanner: true,
    isLoggedOut: false,
    isLoggedIn: false,

    isSignin: false,
    signupModal: false,
    loadingCompleted: false,

    storeInfo: {},
    activeVendor: {},

    cart: {
      items: [],
      expanded: false,
      updating: false,
      categoryTree: [],
      contractItems: [],
      cartKey: "gcfy_cart_token",
      secretKey: "gcfy_7d51t_918af",
    },

    subscriptionCart: {
      item: {},
    },

    wishlistProcessing: false,
    wishlist: {
      items: [],
    },

    snackbar: {
      timeout: 2500,
      show: false,
      text: "",
      color: "success",
      btnColor: "white",
      closeBtnText: "Close",

      submittingText: "Submitting...",
      submittedText: "Your changes have been saved.",
      errorText: "Your changes could not be saved.",
    },

    countries: [],
    commonEnums: undefined,
    calculatedDistances: [],
    country: {
      name: "United States of America",
      countryCode: "USA",
      callingCode: "1",
      currencySymbol: "$",
      correncyCode: "USD",
    },

    meta: {
      razorTestKey: "rzp_test_F8Yy00Kl7ZjNZo",
      razorLiveKey: "rzp_live_zO9BnTCHTqtlHv", // rzp_live_zO9BnTCHTqtlHv
      googleAPIKey: "AIzaSyCUNQg_IQDUQSoQikYXFph77LvtV6WTowg",
      growcifyMasterInventory: "growcify-master-inventory",

      aws: {
        cf: "https://d1fd5e3kpc7pxa.cloudfront.net/",
        s3: "https://growcify-projects.s3.amazonaws.com/",
      },
    },

    editedAddressItem: {},

    gsearch: "",
    gsproducts: [],
    gscategories: [],

    couponsModal: false,
    addressModal: false,
    confirmModal: false,
    cccModal: false,
    oid: false,
    isMobile: window.innerWidth < 600,
  },

  mutations: {
    authSuccess(state, infoObject) {
      const token = infoObject.token;
      delete infoObject.token;

      state.token = token;
      state.uuid = infoObject._id;
      state.userInfo = infoObject;
      state.authenticated = true;

      //store token in the localStorage and headers
      localStorage.setItem("token", token);
      Vue.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    },

    authError(state) {
      state.authenticated = false;
      state.token = state.uuid = "";
      state.userInfo = {};
    },

    verifySuccess(state, infoObject) {
      state.authenticated = true;
      state.userInfo = infoObject;
      state.uuid = infoObject._id;
    },

    modifyPhotos(state, photos) {
      state.userInfo.photos = photos;
    },

    modifyBasicInfo(state, info) {
      if (info.name) state.userInfo.name = info.name;
      if (info.email) state.userInfo.email = info.email;
      if (info.mobile) state.userInfo.mobile = info.mobile;
    },

    updateCart(state) {
      const cart = state.cart;
      const store = state.storeInfo;
      if (!cart.items || !cart.items.length) return;
      const ids = cart.items.map((x) => x._id);

      const cItems = JSON.parse(JSON.stringify(cart.items));
      state.cart.updating = true;

      const isCMV =
        store.isMultivendor && store.multivendorUIType === "categories";
      const requestURI = `product/${isCMV ? "cart/mv" : "cart"}/${store._id}`;

      return Vue.axios
        .post(requestURI, ids, { ngProgress: false })
        .then((res) => {
          const r = res.data;
          let items = [];
          cItems.forEach((x) => {
            const newitem = r.find((c) => c._id === x._id);
            if (newitem) {
              x.price = newitem.price;
              x.marketPrice = newitem.marketPrice;
              x.purchasePrice = newitem.purchasePrice;
              x.name = newitem.name;
              x.size = newitem.size;
              x.unit = newitem.unit;
              x.gst = newitem.gst;
              x.quantity = newitem.quantity;
              x.discount = newitem.discount;
              x.discountBy = newitem.discountBy;
              x.discountIsActive = newitem.discountIsActive;
              x.discountedPrice = newitem.discountedPrice;
              x.discountApplicableForMembershipCustomers =
                newitem.discountApplicableForMembershipCustomers;
              x.category = newitem.category;
              x.incrementor = newitem.incrementor;

              if (x.orderCap > newitem.orderCap) {
                x.orderCap = newitem.orderCap;
                x.qty = newitem.orderCap;
              }
              x.hasProcessed = false;
              x.pricing = newitem.pricing;
              x.price = Cart.getPrice(newitem);

              items.push(x);
            }
          });
          state.cart.items = items;
          Cart.updateLocalStorage();
          state.cart.updating = false;
        })
        .catch((e) => {
          console.log(e);
          state.cart.updating = false;
        });
    },

    logout(state) {
      return new Promise((resolve, reject) => {
        return Vue.axios
          .post("a/logout/" + state.uuid + "")
          .then(() => {
            state.token = "";
            state.authenticated = false;
            localStorage.removeItem("token");

            this.commit("updateCart", state);
            router.push("/").catch(() => {});

            state.snackbar.show = true;
            state.snackbar.text = "Logged out successfully!";
            setTimeout(() => (state.snackbar.show = false), 1500);

            resolve();
          })
          .catch((err) => reject(err));
      });
    },

    unset(state) {
      state.isLoggedOut = state.isLoggedIn = false;
    },

    loggedIn(state) {
      state.isLoggedIn = true;
      setTimeout(() => {
        state.isLoggedIn = false;
      }, 2500);
    },
  },

  actions: {
    // eslint-disable-next-line
    sendOTP({ commit }, uobj) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("a/send-otp/" + this.state.storeInfo._id, uobj)
          .then((resp) => resolve(resp))
          .catch((err) => reject(err));
      });
    },

    verifyOTP({ commit }, uobj) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("a/verify-otp/" + this.state.storeInfo._id, uobj)
          .then((resp) => {
            commit("authSuccess", resp.data);
            commit("loggedIn");
            resolve(resp);

            this.commit("updateCart", this.state);
          })
          .catch((err) => {
            localStorage.removeItem("token");
            commit("authError");
            reject(err);
          });
      });
    },

    verify({ commit }) {
      if (!this.state.storeInfo._id || !this.state.token) return;
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("a/v/" + this.state.storeInfo._id)
          .then((resp) => {
            commit("verifySuccess", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            localStorage.removeItem("token");
            commit("authError");
            reject(err);
          });
      }).catch(() => {});
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.token,

    authStatus: (state) => state.authenticated,

    userInfo: (state) => state.userInfo,

    isTokenAvailable: () => localStorage.getItem("token"),
  },
});

export default store;
