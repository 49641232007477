import $ from "jquery";

import Cart from "../../services/cart";
import Store from "../../services/store";
import Util from "../../services/util";
import NotFound from "../../components/not-found/not-found.vue";
import Breadcrumb from "../../components/breadcrumb/breadcrumb.vue";
import ProductItem from "../../components/product-item/product-item.vue";

export default {
  name: "Product",

  created() {
    this.scrollTop();
    this.breadcrumb = JSON.parse(JSON.stringify(this.$route.meta.breadcrumb));
    if (!this.store._id) return;

    this.slug = this.$route.params.slug;
    this.fetchProduct();
  },

  destroyed() {
    Util.setTitle();
  },

  components: {
    NotFound,
    Breadcrumb,
    ProductItem,
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },
    user() {
      return this.$store.state.userInfo;
    },

    isAuthenticated() {
      return this.$store.state.authenticated;
    },

    children() {
      return this.product &&
        this.product.children &&
        this.product.children.length
        ? this.product.children.length
        : 0;
    },

    hasPhotos() {
      return this.product.photos && this.product.photos.length;
    },

    photos() {
      let photos = this.product.photos;
      if (photos && photos.length)
        photos = photos.filter((x) => x && x.includes("png"));
      if (!photos || !photos.length) return false;
      return photos;
    },

    productGridUI() {
      return this.$store.state.storeInfo.webstore?.productGridUI || 2;
    },

    hasSP() {
      return this.similarProducts && this.similarProducts.length;
    },

    hasRVP() {
      return this.recentlyViewedProducts && this.recentlyViewedProducts.length;
    },

    rvpKey() {
      return this.store._id + "_rvp";
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    hidePricing() {
      return (
        this.store.hideProductPriceIfUserNotApproved &&
        (!this.isAuthenticated ||
          (this.user && this.user.profile && !this.user.profile.isApproved))
      );
    },
  },

  methods: {
    fetchProduct(pop = false) {
      this.loading = true;
      return Store.getProductBySlug(this.store._id, this.slug)
        .then((x) => {
          this.product = x.data;
          this.selectedVariant = x.data;
          this.findDifferentSizes();
          this.findDifferentColors();
          this.processBreadcrumb(pop);
          this.fetchSimilarProducts();
          this.fetchRecentlyViewed();
          this.processRecentlyViewed();
          if (this.hasPhotos) this.activeImage = this.product.photos[0];

          this.scrollTop();
          this.loading = false;
          document.title = this.product.name;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchSimilarProducts() {
      this.sploading = true;
      return Store.getSimilarProducts(this.store._id, this.product._id)
        .then((x) => {
          this.similarProducts = x.data;
          this.sploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.sploading = false;
        });
    },

    fetchRecentlyViewed() {
      let _ids = localStorage.getItem(this.rvpKey);
      if (!_ids) return;

      _ids = JSON.parse(_ids);
      _ids = _ids.filter((x) => x != this.product._id);
      if (_ids.length > 16) _ids.length = 16;

      this.rvploading = true;
      return Store.getProductsByIDs(this.store._id, _ids)
        .then((x) => {
          const items = x.data;
          const formattedArray = [];

          _ids.forEach((p) => {
            const pinfo = items.find((x) => x._id == p);
            if (pinfo) formattedArray.push(pinfo);
          });

          this.recentlyViewedProducts = formattedArray;
          this.rvploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.rvploading = false;
        });
    },

    processRecentlyViewed() {
      let array = [];
      const _id = this.product._id;
      let rvp = localStorage.getItem(this.rvpKey);
      if (rvp) array = JSON.parse(rvp);
      if (!array.includes(_id)) array.unshift(_id);
      else {
        array = array.filter((x) => x != _id);
        array.unshift(_id);
      }
      localStorage.setItem(this.rvpKey, JSON.stringify(array));
    },

    processBreadcrumb(pop = false) {
      if (pop) {
        this.breadcrumb.pop();
        this.breadcrumb.pop();
      }
      this.pushBreadcrumb(
        this.product.category.name,
        "/category/" + this.product.category.slug || this.product.category._id,
        false
      );
      this.pushBreadcrumb(this.product.name);
    },

    pushBreadcrumb(text, url = "", disabled = true) {
      const item = { text: text, disabled: disabled, href: url };
      this.breadcrumb.push(item);
    },

    getPrice(product) {
      return Cart.getPrice(product);
    },

    getSaving(product) {
      return product.marketPrice - product.price;
    },

    findDifferentColors() {
      if (!this.product) return;
      const colors = [];
      if (this.product.color)
        colors.push({
          _id: this.product._id,
          slug: this.product.slug,
          color: this.product.color,
        });
      if (this.product.children) {
        this.product.children.forEach((x) => {
          if (x.color && !colors.find((c) => c.color == x.color))
            colors.push({ _id: x._id, slug: x.slug, color: x.color });
        });
      }
      this.colors = colors.filter((x) => x);
    },

    findDifferentSizes() {
      if (!this.product) return;
      const sizes = [];
      if (this.product.size)
        sizes.push({
          _id: this.product._id,
          slug: this.product.slug,
          size: this.product.size,
          unit: this.product.unit,
          price: this.product.price,
        });
      if (this.product.children) {
        this.product.children.forEach((x) => {
          if (
            !sizes.find(
              (s) => s.size == x.size && s.unit == x.unit && s.price == x.price
            )
          )
            sizes.push({
              _id: x._id,
              slug: x.slug,
              size: x.size,
              unit: x.unit,
              price: x.price,
            });
        });
      }
      this.sizes = sizes;
    },

    getAssetURL(data, sid) {
      return Store.getAssetURL(data, sid);
    },

    getOriginalAssetURL(data, sid) {
      return Store.getOriginalAssetURL(data, sid);
    },

    changeActiveImage(img) {
      this.activeImage = img;
    },

    scrollTop() {
      $("html, body").animate({ scrollTop: 0 }, 100);
    },

    variantError() {
      this.$store.state.snackbar.text = "Unavailable at the moment";
      this.$store.state.snackbar.timeout = 3000;
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    changeVariant(product) {
      const isCMV = this.store.isCMV;
      let slug = isCMV ? product._id : product.slug || product._id;
      if (this.slug == slug) return;

      const pc = product.color;
      const colors = this.colors;
      if (colors && colors.length && pc) {
        const variant = this.product.children.find(
          (x) => x.color == pc && x.size == this.product.size
        );
        if (!variant) return this.variantError();
        slug = isCMV ? variant._id : variant.slug || variant._id;
      }
      this.navigate(slug);
    },

    navigate(slug = this.product.slug || this.product._id) {
      this.$router
        .push({ name: "product", params: { slug: slug } })
        .catch(() => {});
    },

    getSizeTitle(item) {
      return item.size + item.unit;
    },
  },

  watch: {
    "$route.params"(n) {
      this.activeImage = false;
      if (n && n.slug) {
        this.scrollTop();
        this.slug = n.slug;
        this.fetchProduct(true);
      }
    },
  },

  data() {
    return {
      slug: "",
      loading: false,
      sploading: false,
      rvploading: false,
      breadcrumb: [],

      lazy: require("@/assets/imgs/placeholder.png"),

      product: false,
      selectedVariant: {},
      units: [],
      sizes: [],
      colors: [],

      activeImage: false,
      zoomModal: false,

      similarProducts: [],
      recentlyViewedProducts: [],
    };
  },
};
