import moment from "moment";

import Util from "../../../services/util";
import User from "../../../services/user";
import Order from "../../../services/order";
import Store from "../../../services/store";

export default {
  name: "Wallet",

  created() {
    if (this.store.hideWallet || !this.user._id)
      return this.$router.push({ name: "account" }).catch(() => {});
    this.fetchWallet();
    if (this.isMobile) this.items.length = 3;
  },

  methods: {
    fetchWallet() {
      this.loading = true;
      return User.getWallet(this.store._id, this.user._id)
        .then((x) => {
          if (x && x.data) {
            this.wallet = x.data;
            this.fetchWalletHistory();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    fetchWalletHistory() {
      this.hloading = true;
      return User.getWalletHistory(this.store._id, this.wallet._id)
        .then((x) => {
          if (x && x.data) this.history = x.data;
          this.hloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.hloading = false;
        });
    },

    fetchPaymentSetting() {
      if (this.payment) return this.processOnlinePayment();
      this.ploading = true;
      return Store.getPaymentSettings(this.store._id).then(
        (res) => {
          const x = res.data;
          if (x && x.isActive) {
            this.payment = x;
          }

          this.ploading = false;
          this.processOnlinePayment();
        },
        (e) => {
          console.log(e);
          this.ploading = false;
          this.mainerr = true;
        }
      );
    },

    proceed() {
      if (!this.$refs.wallet || !this.$refs.wallet.validate()) return;
      this.fetchPaymentSetting();
    },

    processOnlinePayment() {
      const amount = this.razorpayOptions().amount;
      const currencyCode = this.razorpayOptions().currencyCode;
      const options = { amount: amount, currencyCode: currencyCode };

      this.oloading = true;
      return Order.createRazorpayOrder(this.store._id, options)
        .then((r) => {
          const res = r.data;
          this.payOnline(res.orderId);
          this.oloading = false;
        })
        .catch(() => {
          console.log("could not generate rzp oid");
          this.oloading = false;
          this.payOnline();
        });
    },

    payOnline(orderId = "") {
      const amount = this.razorpayOptions().amount;
      const currencyCode = this.razorpayOptions().currencyCode;
      const options = Util.getRazorpayOptions(
        currencyCode,
        amount,
        "Wallet Recharge",
        this.payment && this.payment.key ? this.payment.key : "",
        orderId
      );

      const newthis = this;
      options.handler = function (response) {
        newthis.processRecharge(response.razorpay_payment_id);
      };
      const razorpay = new window.Razorpay(options);
      razorpay.on("payment.failed", function (response) {
        if (response && response.error && response.error.description) {
          newthis.errorCallBack(response.error.description);
        }
      });
      razorpay.open();
    },

    processRecharge(paymentId) {
      if (!paymentId) return this.errorCallBack();
      this.processing = true;

      const recharge = {
        amount: parseFloat(this.amount),
        paymentId: paymentId,
      };

      return User.rechargeWallet(this.store._id, this.user._id, recharge)
        .then(() => {
          this.processing = this.rechargeModal = false;
          this.amount = undefined;
          this.fetchWallet();
          this.successCallBack();
        })
        .catch(() => {
          this.amount = undefined;
          this.processing = false;
          this.throwError();
        });
    },

    throwError() {
      this.errorCallBack("Oops! Something went wrong; try later.");
    },

    errorCallBack(text = "") {
      this.$store.state.snackbar.text =
        text || "Something went wrong, try again";
      this.$store.state.snackbar.timeout = 3000;
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    successCallBack() {
      this.$store.state.snackbar.text = "Wallet Recharge Successful!";
      this.$store.state.snackbar.timeout = 3000;
      this.$store.state.snackbar.color = "success";
      this.$store.state.snackbar.show = true;
    },

    razorpayOptions() {
      const amount = parseFloat((this.amount * 100).toFixed(2));
      return { amount: amount, currencyCode: this.currencyCode };
    },

    formatDate(date, format = "lll") {
      return moment(date).format(format);
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    },

    currencyCode() {
      return this.store.userCountry && this.store.userCountry.currencyCode
        ? this.store.userCountry.currencyCode
        : "INR";
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  data() {
    return {
      loading: false,
      hloading: false,
      ploading: false,
      oloading: false,
      processing: false,
      rechargeModal: false,

      wallet: false,
      history: false,

      amount: "",

      items: [
        { _id: 1, amount: 100 },
        { _id: 2, amount: 200 },
        { _id: 3, amount: 500 },
        { _id: 4, amount: 1000 },
        { _id: 5, amount: 2500 },
        { _id: 6, amount: 5000 },
      ],

      validationRules: {
        amountRule: [
          (v) => (!!v && !isNaN(v) && v > 0) || "Enter a valid amount",
        ],
      },
    };
  },
};
