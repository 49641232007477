import $ from "jquery";

import Util from "../../services/util";
import Store from "../../services/store";

import SectionHeader from "../../components/section-header/section-header.vue";
import ProductItem from "../../components/product-item/product-item.vue";
import Breadcrumb from "../../components/breadcrumb/breadcrumb.vue";
import NotFound from "../../components/not-found/not-found.vue";

export default {
  name: "Category",

  created() {
    $("html, body").animate({ scrollTop: 0 }, 0);

    this.slug = this.$route.params.slug;
    this.breadcrumb = JSON.parse(JSON.stringify(this.$route.meta.breadcrumb));
    if (!this.store._id) return;

    this.fetchCategory();
    this.fetchBrands();
    this.fetchCommonEnums();
    this.loadMore();
  },

  destroyed() {
    Util.setTitle();
  },

  components: {
    SectionHeader,
    ProductItem,
    Breadcrumb,
    NotFound,
  },

  methods: {
    fetchCategory(pop = false) {
      this.loading = true;
      return Store.getCategory(this.store._id, this.slug)
        .then((x) => {
          this.category = x.data;
          if (!this.category) return this.$router.push({ path: "/404" });

          this.pushBreadcrumb(pop);

          if (this.category.parent) {
            this.fetchProducts();
            this.fetchSubcategories(true);
          } else {
            this.fetchProducts(true);
            this.fetchSubcategories();
          }

          this.loading = false;
          document.title = this.category.name;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchProducts(
      all = !this.category.parent,
      infinite = false,
      page = this.productsObject.pageNumber,
      pageSize = this.productsObject.itemsPerPage
    ) {
      this.ploading = true;

      const filter = {};
      const sc = this.selectedCategories;
      const sb = this.selectedBrands;
      const sp = this.selectedPricing;
      if (sc && sc.length) filter.categories = sc;
      if (sb && sb.length) filter.brands = sb;
      if (sp && sp.length) filter.pricing = sp;
      const filterObject = { filters: filter, sortBy: this.sortBy };

      let Request;
      if (!all)
        Request = Store.getProductsByCategory(
          this.store._id,
          this.category._id,
          filterObject,
          page,
          pageSize,
          this.search
        );
      else
        Request = Store.getAllProductsByMainCategory(
          this.store._id,
          this.category._id,
          filterObject,
          page,
          pageSize,
          this.search
        );

      return Request.then((x) => {
        this.productsObject = x.data;

        if (!infinite) this.products = this.productsObject.items;
        else this.productsObject.items.forEach((i) => this.products.push(i));

        this.ploading = false;
      }).catch((e) => {
        console.log(e);
        this.ploading = false;
      });
    },

    loadMore() {
      window.onscroll = () => {
        const bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 300 >=
          document.documentElement.offsetHeight;

        setTimeout(() => {
          if (bottomOfWindow && !this.ploading && !this.loading) {
            if (this.products.length == this.productsObject.totalItems) return;
            const page = this.productsObject.pageNumber + 1;
            this.fetchProducts(!this.category.parent, true, page);
          }
        });
      };
    },

    fetchSubcategories(subling = false) {
      this.scloading = true;

      let Request;
      if (!subling) {
        Request = Store.getSubCategories(this.store._id, this.category._id);
      } else {
        Request = Store.getSiblingCategories(this.store._id, this.category._id);
      }

      return Request.then((x) => {
        this.subcategories = x.data;
        this.scloading = false;
        this.ensureIfSelected();
      }).catch((e) => {
        console.log(e);
        this.scloading = false;
      });
    },

    ensureIfSelected() {
      if (this.subcategories.some((x) => x._id == this.category._id))
        this.selectedCategories.push(this.category._id);
      this.subcategories.sort((a, b) => a.name > b.name);
    },

    fetchBrands() {
      this.bloading = true;
      return Store.getBrands(this.store._id)
        .then((x) => {
          this.brands = x.data;
          this.bloading = false;
          this.brands.sort((a, b) => a.name > b.name);
        })
        .catch((e) => {
          console.log(e);
          this.bloading = false;
        });
    },

    fetchCommonEnums() {
      this.celoading = true;
      return Store.getCommonEnums()
        .then((x) => {
          this.enums = x.data;
          this.celoading = false;
          if (this.enums) {
            this.pricing = this.enums.pricingFilter;
            this.sortOptions = this.enums.sortFilter;
          }
        })
        .catch((e) => {
          console.log(e);
          this.celoading = false;
        });
    },

    pushBreadcrumb(pop) {
      const item = { text: this.category.name, disabled: true, href: "" };
      if (pop) this.breadcrumb.pop();
      this.breadcrumb.push(item);
    },

    currencyPipe(amount) {
      return this.$options.filters.fc(amount);
    },

    getPricingText(item) {
      return (
        this.currencyPipe(item.gte) +
        (item.lte ? ` to ${this.currencyPipe(item.lte)}` : " and above")
      );
    },

    resetSelected() {
      this.selectedBrands = [];
      this.selectedPricing = [];
      this.selectedCategories = [];
    },

    jsonString(s) {
      return JSON.stringify(s);
    },
  },

  watch: {
    "$route.params"(n) {
      if (n && n.slug) {
        this.slug = n.slug;
        this.resetSelected();
        this.fetchCategory(true);
      }
    },

    selectedBrands(n, o) {
      if (this.jsonString(n) == this.jsonString(o)) return;
      this.productsObject.pageNumber = 1;
      this.fetchProducts();
    },

    selectedPricing(n, o) {
      if (this.jsonString(n) == this.jsonString(o)) return;
      this.productsObject.pageNumber = 1;
      this.fetchProducts();
    },

    selectedCategories(n, o) {
      if (this.jsonString(n) == this.jsonString(o)) return;
      this.productsObject.pageNumber = 1;
      this.fetchProducts();
    },

    sortBy() {
      this.productsObject.pageNumber = 1;
      this.fetchProducts();
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    productGridUI() {
      return this.$store.state.storeInfo.webstore?.productGridUI || 2;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  data() {
    return {
      slug: "",
      breadcrumb: [],

      loading: false,
      ploading: false,
      scloading: false,
      celoading: false,
      bloading: false,
      category: {},
      enums: {},

      hideFilters: false,

      brands: [],
      pricing: [],
      subcategories: [],

      selectedBrands: [],
      selectedPricing: [],
      selectedCategories: [],

      sortBy: 0,
      sortOptions: [],

      search: "",
      products: [],
      productsObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 24,
      },
    };
  },
};
