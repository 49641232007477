import Store from "../../services/store";

export default {
  name: "Signup",

  created() {},

  watch: {
    "$store.state.signupModal"(n) {
      this.si = this.$store.state.storeInfo;
      if (n) this.fetchCountries();
    },

    "$store.state.isSignin"() {
      this.otpSent = false;
    },
  },

  methods: {
    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) {
        this.countries = cs;
        this.processDefaultCountry();
      }

      this.processing = true;
      return Store.getActiveCountries()
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
          this.processDefaultCountry();
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    processDefaultCountry() {
      const defaultCountry = this.countries.find(
        (x) => x._id == this.si.country._id
      );
      if (defaultCountry) {
        this.selectedCountry = defaultCountry;
        this.user.callingCode = defaultCountry.callingCode;
      }
    },

    startTimer() {
      this.resendOTPButton = false;
      this.seconds = 59;
      this.myTimer = setInterval(() => {
        this.seconds--;
        if (this.seconds === 0) {
          this.resendOTPButton = true;
          if (this.myTimer) window.clearInterval(this.myTimer);
        }
      }, 1000);
    },

    countryText(item) {
      return "+" + item.callingCode;
    },

    submit() {
      this.otpSent ? this.verifyOTP() : this.sendOTP();
    },

    sendOTP(forceSend = false) {
      if (!this.$refs.form.validate() && !forceSend) return;
      this.processing = true;

      if (this.selectedCountry._id) {
        this.user.callingCode = this.selectedCountry.callingCode;
        this.user.country = this.selectedCountry._id;
      }

      this.startTimer();
      this.$store
        .dispatch("sendOTP", this.user)
        .then(() => {
          this.processing = false;
          this.otpSent = true;

          this.$store.state.snackbar.text =
            "We have sent you an OTP to verify.";
          this.$store.state.snackbar.timeout = 3000;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;
        })
        .catch(() => {
          this.processing = false;
          setTimeout(() => {
            this.showError = false;
          }, 2500);

          this.$store.state.snackbar.text = "Something went wrong, try again";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "error";
          this.$store.state.snackbar.show = true;
        });
    },

    verifyOTP() {
      if (!this.$refs.form.validate()) return;

      this.processing = true;
      this.$store
        .dispatch("verifyOTP", this.user)
        .then(() => {
          this.processing = false;

          this.$store.state.snackbar.text = "OTP Verified Successfully";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;

          if (this.myTimer) window.clearInterval(this.myTimer);
          this.$store.state.signupModal = false;
        })
        .catch((err) => {
          this.processing = false;
          if (err.response.status === 401)
            this.showError = err.response.data.message;

          this.$store.state.snackbar.text =
            this.showError || "Something went wrong, try again";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "error";
          this.$store.state.snackbar.show = true;
        });
    },
  },

  computed: {
    getTimer() {
      return "00:" + (this.seconds < 10 ? "0" + this.seconds : this.seconds);
    },

    isSignin() {
      return this.$store.state.isSignin;
    },

    numberRules() {
      if (this.selectedCountry && this.selectedCountry._id) {
        const length = this.selectedCountry.minNumberLength || 10;
        return [
          (v) =>
            (!!v &&
              !isNaN(this.user.mobile) &&
              this.user.mobile.length >= length) ||
            "Mobile number is required",
        ];
      }
      return [];
    },
  },

  data() {
    const emailregex =
      /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      si: {},
      valid: false,
      loading: false,
      processing: false,

      otpSent: false,
      resendOTPButton: false,
      countries: [],
      selectedCountry: {},

      myTimer: false,
      seconds: 59,
      user: {
        name: "",
        mobile: "",
        countryCode: "",
      },

      validationRules: {
        mobileRules: [
          (v) => !!v || "Mobile number is required",
          (v) => /^[0]?[6789]/.test(v) || "Please enter a valid mobile number",
        ],
        otpRules: [
          (v) =>
            (v && this.otpSent && !isNaN(v) && v.length === 6) ||
            "OTP is required",
        ],
        basicRule: [(v) => !!v || "Name is required"],
        emailRule: [(v) => !v || emailregex.test(v) || "Must be a valid email"],
        emailRequiredRule: [
          (v) => (v && emailregex.test(v)) || "Must be a valid email",
        ],
      },
    };
  },
};
