import moment from 'moment';

import Cart from '../../../services/cart';
import Store from '../../../services/store';

import SubscriptionBillingComponent from '../subscription-billing/subscription-billing.vue';

export default {
  name: 'SubscriptionComponent',

  components: {
    SubscriptionBillingComponent
  },

  props: [
    'items',
    'order',
    'enums',
    'errors',
    'completed',
    'membership',
    'subscription'
  ],

  methods: {
    getAssetURL(data, sid) {
      return Store.getAssetURL(data, sid);
    },

    initiateClearCart() {
      this.$store.state.cccModal = true;
    },

    getSaving(item) {
      return item.marketPrice - item.price;
    },

    itemTotal(item) {
      return parseFloat((item.qty * item.price).toFixed(2));
    },

    calculateTax(amount, tax) {
      let taxAmount = 0;
      if (amount && tax) taxAmount = parseFloat(((amount * tax) / 100).toFixed(2));
      return taxAmount;
    },
  
    totalItemTax(item) {
      const cartItem = Cart.findProduct(item);
      const amount = cartItem.beforeTaxPrice * cartItem.qty;
      return this.calculateTax(amount, cartItem.gst);
    },
  
    beforeTaxTotal(item) {
      const cartItem = Cart.findProduct(item);
      return cartItem.beforeTaxPrice * cartItem.qty;
    },
    
    validatePincode() {
      this.order.pincodeVerified = this.invalidPincode = this.errors.pincode = this.order.pincodeObj = false;
      if (this.$refs.pform && !this.$refs.pform.validate()) return;
      if (!this.locations || !this.locations.length) return;

      const pobj = this.locations.find(x => x.pincode === this.order.pincode);
      if (!pobj) {
        this.invalidPincode = true;
        this.errors.pincode = true;
      } else {
        this.order.pincodeObj = pobj;
        this.order.pincodeVerified = true;
      }
    },

    formatDate(date, format = 'll') {
      return moment(date).format(format);
    },

    increase() {
      this.subscription.qty++; 
    },
  
    decrease() {
      if (this.subscription.qty > 1) this.subscription.qty--; 
    },

    generateSchedule() {
      let startDate = this.subscription.startDate;
      let endDate = this.subscription.endDate;
      const dcycle = this.subscription.cycle;
      const dperiod = this.subscription.period;
      const schedule = [];

      if (startDate && endDate) {
        let cycle = 'days';
        if (dcycle === 'weekly') cycle = 'weeks';
        else if (dcycle === 'monthly') cycle = 'months';
        else if (dcycle === 'yearly') cycle = 'years';

        if (cycle) {
          let isAlternate = dcycle === 'alternate-days';
          let index = 1;

          while(moment(startDate).isSameOrBefore(moment(endDate))) {
            if (!schedule.length) {
              schedule.push({
                date: moment(startDate).format("YYYY-MM-DD"),
                status: 'pending',
                index: index+'.',
                period: dperiod
              });
            }  else {
              schedule.push({
                date: startDate,
                status: 'pending',
                index: index+'.',
                period: dperiod
              });
            }
            startDate = moment(startDate).add(isAlternate ? 2 : 1, cycle).format("YYYY-MM-DD");
            index++;
          }
        }
      }
      this.subscription.schedule = schedule;
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    logo() {
      return Store.logoURL();
    },

    locations() {
      return this.$parent.locations;
    },

    wallet() {
      return this.$parent.wallet;
    },

    qty() {
      return this.subscription.product.qty;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  watch: {
    'subscription.startDate'() {
      this.generateSchedule();
    },

    'subscription.endDate'() {
      this.generateSchedule();
    },

    'subscription.cycle'() {
      this.generateSchedule();
    },

    'subscription.period'() {
      this.generateSchedule();
    }
  },

  data() {
    return {
      invalidPincode: false,

      servicablePin: false,
      showNotes: false,

      showSchedule: true,

      startDateModal: false,
      endDateModal: false,

      validationRules: {
        pincodeRule: [
          v => v && v.length === 6 || 'Enter a valid 6 digit pincode'
        ],
      }
    }
  }
}