import CryptoJS from "crypto-js";

import store from "../store";
import Store from "./store";

export default {
  encrypt(text, key) {
    return CryptoJS.AES.encrypt(text, key).toString();
  },

  decrypt(ciphertext, key) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    const output = bytes.toString(CryptoJS.enc.Utf8);
    return output;
  },

  openWhatsApp(number, message = "") {
    let callingCode = "";
    if (!number.includes("+")) {
      const country = store.state.storeInfo.country;
      if (country) callingCode = country.callingCode || "91";
    }
    window.open(
      "https://api.whatsapp.com/send?phone=" +
        callingCode +
        number +
        "&text=" +
        message,
      "_system"
    );
  },

  getRazorpayOptions(
    currency,
    amount,
    description,
    razorpayKey = "",
    orderId = ""
  ) {
    const storeInfo = store.state.storeInfo;
    const userInfo = store.state.userInfo;

    const options = {
      description: storeInfo.appName + " - " + description,
      currency: currency,
      key: razorpayKey ? razorpayKey : store.state.meta.razorLiveKey,
      amount: amount,
      name: storeInfo.appName || "",
      prefill: {
        email: userInfo.email || "",
        contact: userInfo.mobile || "",
        name: userInfo.name || "",
      },
      theme: { color: storeInfo.appColor },
    };

    if (orderId) options.order_id = orderId;
    if (storeInfo.appLogo) options.image = Store.getAssetURL(storeInfo.appLogo);

    return options;
  },

  isSinglevendorPremiumPlan(plan) {
    return ["buy-growcify-premium-singlevendor"].includes(plan);
  },

  isPremiumPlan(plan) {
    return [
      "rent-growcify-premium",
      "buy-growcify-premium",
      "buy-growcify-enterprise",
      "growcify-pay-as-you-grow",
      "growcify-premium",
      "growcify-enterprise",
    ].includes(plan);
  },

  generateSlug(string) {
    return string
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  },

  setTitle() {
    const st = store.state.storeInfo;
    document.title = st.webstore?.title || st.appName;
  },
};
