import Util from '../../../services/util';
import Store from '../../../services/store';

export default {
  name: 'Success',

  created() {
    if (!this.store._id || !this.oid) return this.$router.push({ path: '/' }).catch(() => {});
    document.title = this.store.appName;
  },

  methods: {
    openWhatsApp() {
      if (!this.wan) return;
      const wam = this.store.whatsappMessage;
      Util.openWhatsApp(this.wan, wam);
    },

    gotoHome() {
      this.$router.push({ path: '/' }).catch(() => {});
    },

    navigate(slug = 'orders') {
      this.$router.push({ name: 'account-slug', params: {slug: slug} }).catch(() => {});
    },
  },

  destroyed() {
    this.$store.state.oid = false;
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    wan() {
      return this.store.whatsappNumber;
    },

    oid() {
      return this.$store.state.oid;
    },

    logo() {
      return Store.logoURL();
    },

    isSubscription() {
      return this.$route.name == 'checkout-subscription-success';
    }
  },
  
  data() {
    return {
      
    }
  }
}