<template>
  <section v-if="banners && banners.length">
    <v-row v-if="type == 'main'">
      <v-col>
        <div v-if="!fullscreen" class="mt-5"></div>
        <v-carousel cycle :show-arrows="false" hide-delimiter-background delimiter-icon="mdi-minus" :height="isMobile ? '145px' : '475px'">
          <v-carousel-item @click="redirectTo(slide)" v-for="(slide, i) in banners" :key="i">
            <v-img v-if="slide.type == 'image'" 
              :height="!isMobile ? 475 : ''"
              :src="getAssetURL(slide.file)" 
              :class="['align-center', 
                fullscreen ? '' : 'rounded-lg',
                slide.contentAlignment == 'left' ? 'pl-12' : '', 
                slide.contentAlignment == 'center' ? 'text-center' : '',
                slide.contentAlignment == 'right' ? 'text-right pr-12' : '',
                slide.redirectTo ? 'link' : '']">

              <template v-slot:placeholder><ImageLoader></ImageLoader></template>

              <div v-if="slide.showContent" class="main-banner-content">
                <h1 class="mb-4" v-html="slide.title"></h1>
                <p class="mb-4" v-html="slide.description"></p>
                <div class="mt-5">
                  <v-btn rounded large class="nott" color="primary">{{ slide.buttonText || 'Shop Now' }}</v-btn>
                </div>
              </div>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <v-row v-if="type == 'mini'">
      <v-col cycle v-for="(slide, i) in banners" :key="i" sm="6" cols="12" @click="redirectTo(slide)">
        <v-img v-if="slide.type == 'image'" :src="getAssetURL(slide.file)" 
          :class="['rounded-lg align-center', 
            !slide.contentAlignment || slide.contentAlignment == 'left' ? 'pl-8' : '', 
            slide.contentAlignment == 'center' ? 'text-center' : '',
            slide.contentAlignment == 'right' ? 'text-right pr-8' : '',
            slide.redirectTo ? 'link' : '']">

          <template v-slot:placeholder><ImageLoader></ImageLoader></template>

          <div v-if="slide.showContent" class="main-banner-content">
            <h2 v-html="slide.title"></h2>
            <span v-html="slide.description"></span>
            <div class="mt-3">
              <v-btn rounded small class="nott" color="primary">{{ slide.buttonText || 'Shop Now' }}</v-btn>
            </div>
          </div>
        </v-img>
      </v-col>
    </v-row>
    <div v-if="ismb" class="mb-8"></div>
  </section>
</template>
<script>
import Store from '../../services/store';

export default {
  name: 'MainBanners',

  props: [
    'ismb',
    'type',
    'banners',
    'fullscreen'
  ],

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  methods: {
    getAssetURL(data) {
      return Store.getAssetURL(data);
    },

    redirectTo(item) {
      if (item.redirectTo === 'category' && item.redirectToCategory) {
        if (item.redirectToCategory.isActive && !item.redirectToCategory.deleted) {
          this.$router.push({ name: 'category', params: { slug: item.redirectToCategory.slug || item.redirectToCategory._id } });
        }
      } else if (item.redirectTo === 'product' && item.redirectToProduct) {
        if (item.redirectToProduct.isActive && !item.redirectToProduct.deleted) {
          this.$router.push({ name: 'product', params: { slug: item.redirectToProduct.slug || item.redirectToProduct._id } });
        }
      } else if (item.redirectTo === 'group' && item.redirectToGroup) {
        if (item.redirectToGroup.isActive && !item.redirectToGroup.deleted) {
          this.$router.push({ name: 'collection', params: { slug: item.redirectToGroup.slug || item.redirectToGroup._id } });          
        }
      } else if (item.redirectTo === 'url' && item.redirectToURL) { 
        window.open(item.redirectToURL, '_system'); 
      }

      // else if (item.redirectTo === 'vendor' && item.redirectToVendor) {
      //   if (item.redirectToVendor.listInApp && item.redirectToVendor.isActive && !item.redirectToVendor.deleted) {
      //     this.$router.push({ name: 'store', params: { slug: item.redirectToVendor.slug || item.redirectToVendor._id } });          
      //   }
      // } 
    }
  }
}
</script>