<template>
  <v-container>
    <div class="mt-10"></div>
    <v-row>
      <v-col>
        <h2 class="mt-10">Cancellation Policy</h2>
        <div class="mb-10"></div>

        <div v-if="loading">
          <v-progress-linear indeterminate height="3px"></v-progress-linear>
        </div>

        <div v-else-if="policy" v-html="policy.description"></div>
        <div v-else>-</div>

        <div class="mb-15"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import $ from 'jquery';
import Util from '../../services/util';
import Store from '../../services/store';

export default {

  created() {
    $('html, body').animate({ scrollTop: 0 }, 250);
    document.title = 'Cancellation Policy';

    this.fetchPolicy();
  },

  destroyed() {
    Util.setTitle();
  },

  methods: {
    fetchPolicy() {
      this.loading = true;
      return Store
        .getPolicy(this.store._id, 'cancellation')  
        .then(x => {
          this.policy = x.data;
          
          if (this.policy && this.policy.length) this.policy = this.policy[0];
          else this.policy = false;

          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    }
  },

  data() {
    return {
      loading: false,
      policy: '',
    }
  }
}
</script>