import $ from "jquery";
import Store from "../../services/store";

import NotFound from "../../components/not-found/not-found.vue";
import ProductItem from "../../components/product-item/product-item.vue";
import CategoryItem from "../../components/category-item/category-item.vue";

export default {
  name: "Search",

  created() {
    this.scrollTop();
  },

  components: {
    NotFound,
    ProductItem,
    CategoryItem,
  },

  methods: {
    scrollTop() {
      $("html, body").animate({ scrollTop: 0 }, 100);
    },

    search() {
      if (!this.gsearch) return;
      this.gsearching = true;
      return Store.search(this.store._id, this.gsearch)
        .then((x) => {
          if (!x.data.products || !x.data.categories) return;

          this.$store.state.gsproducts = x.data.products;
          this.$store.state.gscategories = x.data.categories;

          this.gsearching = false;
          this.fsearched = true;
        })
        .catch((e) => {
          console.log(e);
          this.gsearching = false;
        });
    },
  },

  computed: {
    gsearch() {
      return this.$store.state.gsearch;
    },

    store() {
      return this.$store.state.storeInfo;
    },

    products() {
      return this.$store.state.gsproducts;
    },

    categories() {
      return this.$store.state.gscategories;
    },
  },

  watch: {
    gsearch() {
      this.search();
    },
  },

  data() {
    return {
      gsearching: false,
      fsearched: false,

      lastInput: "",
    };
  },
};
