import moment from 'moment';

import Util from '../../../services/util';
import Store from '../../../services/store';
import Order from '../../../services/order';

import NotFound from '../../../components/not-found/not-found.vue';

export default {
  name: 'Orders',

  created() {
    if (!this.user._id) return this.$router.push({ name: 'account' }).catch(() => {});
    this.fetchOrders();
  },

  components: {
    NotFound
  },

  methods: {
    fetchOrders(loading = true) {
      this.loading = loading;
      this.oloading = !loading;
      return Order
        .getOrders(this.store._id, this.user._id)
        .then(x => {
          this.orders = x.data;
          this.loading = this.oloading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = this.oloading = false;
        });
    },
    
    openURL(url) {
      window.open(url, '_system');
    },

    getName(order) {
      return order.address ? order.address.name : (order.user ? (order.user.name || '-') : '-')
    },

    getCallingCode(item) {
      let cc = this.$store.state.country.callingCode || '91';
      if (item.address && item.address.callingCode) cc = item.address.callingCode;
      else if (!item.address && item.user && item.user.callingCode) cc = item.user.callingCode;
      return cc;
    },

    formatDate(date, format = 'MMM D, h:mm A') {
      return moment(date).format(format);
    },

    getTimeDifference(startAt, endAt) {
      return this.secondsToHms(moment(endAt).diff(startAt, 'seconds'));
    },

    getNumberWithOrdinal(n) {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);
  
      let hDisplay = h > 0 ? h + (h == 1 ? "h, " : "h, ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? "m, " : "m, ") : "";
      let sDisplay = s >= 0 ? s + (s == 1 ? "s" : "s") : "";
      return hDisplay + mDisplay + sDisplay; 
    },

    canCancel(order) {
      return order.status === 'new' || 
        (order.status === 'accepted' && this.store.canCanceOrderOnceAccepted) || 
        (order.status === 'shipped' && this.store.canCanceOrderOnceShipped);
    },

    openWhatsApp(order) {
      if (!this.wan) return;
      const wam = 'OID: *'+order.oid+'*';
      Util.openWhatsApp(this.wan, wam);
    },

    navigate(address) {
      const url = `https://www.google.com/maps/search/?api=1&query=${address.lat},${address.long}`;
      window.open(url, '_system');
    },

    getAssetURL(data, sid) {
      return Store.getAssetURL(data, sid);
    },

    initCancel(order) {
      this.editedItem = order;
      this.cancelModal = true;
    },

    cancelOrder() {
      this.processing = true;
      return Order
        .cancelOrder(this.editedItem._id)
        .then(() => {
          this.fetchOrders(false);
          this.processing = this.cancelModal = false;
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        });
    },

    color(status) {
      if (['new', 'accepted'].includes(status)) return 'primary';
      if (status == 'delivered') return 'success';
      if (status == 'shipped') return 'warning';
      if (status == 'cancelled') return 'error';
      return '';
    }
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    user() {
      return this.$store.state.userInfo;
    },

    wan() {
      return this.store.whatsappNumber;
    },

    logo() {
      return Store.logoURL();
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  data() {
    return {
      orders: [{ _id: 1, expanded: false }], 
      editedItem: {},

      loading: false,
      oloading: false,
      processing: false,
      cancelModal: false
    }
  }
}