import $ from 'jquery';

import Util from '../../services/util';
import Store from '../../services/store';

import SectionHeader from '../../components/section-header/section-header.vue';
import CategoryItem from '../../components/category-item/category-item.vue';
import ProductItem from '../../components/product-item/product-item.vue';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.vue';
import NotFound from '../../components/not-found/not-found.vue';

export default {
  name: 'Deal',

  created() {
    $('html, body').animate({ scrollTop: 0 }, 0);
    this.breadcrumb = JSON.parse(JSON.stringify(this.$route.meta.breadcrumb));
    if (!this.store._id) return;

    this.slug = this.$route.params.slug;
    this.fetchDeal();
    this.fetchCategories();
  },

  destroyed() {
    Util.setTitle();
  },

  components: {
    SectionHeader,
    CategoryItem,
    ProductItem,
    Breadcrumb,
    NotFound 
  },

  methods: {
    pushBreadcrumb(text = this.titleText) {
      this.breadcrumb.push({ text: 'Deal', disabled: true });
      
      const item = { text: text, disabled: true, href: '' };
      this.breadcrumb.push(item);
    },

    fetchDeal() {
      this.loading = true;
      return Store
        .getDeal(this.store._id, this.slug)
        .then(x => {
          if (x.data) {
            this.deal = x.data;
            this.items = this.deal.items;
            document.title = this.deal.title;
            this.pushBreadcrumb(this.deal.title);
          }
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchCategories() {
      this.cloading = true;
      return Store
        .getCategories(this.store._id, 12, false)
        .then(x => {
          this.categories = x.data;
          this.cloading = false;
        })
        .catch(e => {
          console.log(e);
          this.cloading = false;
        });
    },
  },

  computed: {
    store() {
      return this.$store.state.storeInfo;
    },

    titleText() {
      if (!this.deal) return '';
      return this.deal.title;
    },

    productGridUI() {
      return this.store.webstore?.productGridUI || 2;
    },

    categoryGridUI() {
      return this.store.webstore?.categoryGridUI || 2;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      slug: '',
      breadcrumb: [],

      deal: {},
      items: [],

      loading: false,
      cloading: false,

      categories: [],
    }
  }
}