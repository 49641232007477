import Vue from 'vue';
import store from '../store';

export default {
  si() { //storeInfo
    return store.state.storeInfo;
  },

  placeOrder(_id, order) {
    const isCMV = this.si().isCMV;
    const mvquery = isCMV ? 'mv-create' : 'create';

    return Vue.axios.post(`order/${mvquery}/${_id}`, order, {ngProgress: false});
  },

  cancelOrder(orderId) {
    return Vue.axios.put(`order/cancelled/${orderId}`, {}, {ngProgress: false});
  },

  placeAbandonedOrder(_id, order) {
    return Vue.axios.post(`order/abandoned/${_id}`, order, {ngProgress: false});
  },

  updateAbandonedOrder(_id, order) {
    return Vue.axios.put(`order/abandoned/${_id}`, order, {ngProgress: false});
  },

  createRazorpayOrder(_id, options) {
    return Vue.axios.post(`order/razorpay/${_id}`, options, {ngProgress: false});
  },

  validateCoupon(_id, options, isSubscription = false) {
    let endpoint = `coupon/validate/${_id}`;
    if (isSubscription) endpoint = `coupon/validate-subscription/${_id}`;

    return Vue.axios.post(endpoint, options, {ngProgress: false});
  },

  getLastOrder(_id, userId) {
    return Vue.axios.get(`order/last-order/${_id}/${userId}`, {ngProgress: false});
  },

  getOrders(_id, userId) {
    return Vue.axios.get(`order/list/${_id}/${userId}`, {ngProgress: false});
  },

  //subscriptions
  createSubscription(_id, subscription) {
    return Vue.axios.post(`subscription/${_id}`, subscription, {ngProgress: false});
  }
}