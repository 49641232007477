<template>
  <v-row>
    <v-col><small>Copyright © {{ year }}  {{ $store.state.storeInfo.name }}</small></v-col>
    <v-col cols="12" sm="6" class="text-center"><small>By continuing, you agree to our Terms and Conditions and Privacy Policy.</small></v-col>
    <v-col class="text-right"><small>All rights reserved.</small></v-col>
  </v-row>
</template>

<script>
export default {
  
  computed: {
    year() {
      const d = new Date();
      return d.getFullYear();
    }
  },

  data() {
    return {

    }
  }
}
</script>